<template>
  <!-- 진행중인 업무 >> 수입 -->
  <div>
    <div class="content_box mt10">
      <div class="flex_box">
        <span class="tot_num">{{ $t('msg.ONEX010T010.011') }}<!-- Total --> : <span class="num">{{ totalCount }}</span></span>
        <span class="input_box">
          <input type="text" id="filter" class="wid300" v-model="txtFilter" @keyup="changeFilter()" autocomplete="off" :placeholder="$t('msg.ONEX010T010.012')">
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid100" v-model="selPo">
            <option value="POL">Departure</option>
            <option value="POD">Arrival</option>
          </select>
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid200" v-model="selCtr" @change="checkSelectCtr()">
            <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="ctrInfo in ctrCdList" :key="ctrInfo.ctrCd" :value="ctrInfo.ctrCd">{{ ctrInfo.ctrEnm }}</option>
          </select>
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
      </div>
      <div id="realgrid" :style="`width: 100%; height: ${gridHeight}px`" />
    </div>
    <!-- request_area -->
    <div class="request_area">
      <div class="top" @click.prevent="openRequestArea()" style="cursor:pointer">
        <span class="tit">{{ $t('msg.ONEX010T010.013') }}</span><!-- 요청사항 -->
        <span class="ntx">{{ $t('msg.ONEX010T010.014') }}<span> ({{ $t('msg.ONEX010T010.138', { cnt: checkedCount }) }})</span></span><!-- 진행중인 업무를 선택(중복 선택 가능)하고 필요한 요청을 클릭하기 바랍니다. -->
        <button class="reqbtn up" @click.prevent="openRequestArea()"><span>{{ $t('msg.ONEX010T010.015') }}</span></button><!-- 열기 -->
      </div>
      <div class="sub_area" @click.prevent="closeRequestArea()" style="display: block; cursor: pointer;" id="working-progress-import-request-area">
        <div class="top">
          <span class="tit">{{ $t('msg.ONEX010T010.013') }}</span><!-- 요청사항 -->
          <span class="ntx">{{ $t('msg.ONEX010T010.014') }}<span> ({{ $t('msg.ONEX010T010.138', { cnt: checkedCount }) }})</span></span><!-- 진행중인 업무를 선택(중복 선택 가능)하고 필요한 요청을 클릭하기 바랍니다. -->
          <button class="reqbtn down" @click.prevent="closeRequestArea()"><span>{{ $t('msg.ONEX010T010.016') }}</span></button><!-- 닫기 -->
        </div>
        <div class="list">
          <div class="item">
            <p>{{ $t('msg.ONEX010T010.023') }}</p><!-- 정산 -->
            <ul>
              <li :class="requestAreaBtn.charge01 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestCharge('01')">{{ $t('msg.ONEX010T010.024') }}</a></li><!-- 운임 정정 요청 -->
              <li :class="requestAreaBtn.charge02 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestCharge('02')">{{ $t('msg.ONGO080G010.034') }}</a></li><!-- Freetime 연장 요청 -->
              <!--li :class="memberDetail.userCtrCd === 'ID' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestCharge('03')">{{ $t('msg.ONIM099P060.001') }}</a></li--><!-- Payment -->
            </ul>
          </div>
          <div v-show="showDoUl()" class="item">
            <p>{{ $t('msg.ONEX010T010.060') }}</p><!-- D/O -->
            <ul>
              <li :class="requestAreaBtn.do01 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestDO('01')">{{ $t('msg.ONEX010T010.087') }}</a></li><!-- D/O 발행신청 -->
              <li v-if="auth.userCtrCd === 'KR'" :class="requestAreaBtn.do02 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestDO('02')">{{ $t('msg.ONEX010T010.088') }}</a></li><!-- Surrender 문의 -->
              <li v-if="auth.userCtrCd === 'KR'" :class="requestAreaBtn.do03 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestDO('03')">{{ $t('msg.ONEX010T010.089') }}</a></li><!-- D/O (OFC) 전송 -->
            </ul>
          </div>
          <div v-if="memberDetail.userCtrCd === 'KR'" class="item">
            <p>{{ $t('msg.ONEX010T010.030') }}</p><!-- 운송 -->
            <ul>
              <li :class="requestAreaBtn.trans01 === 'Y' ? '' : 'disabled'"><a href="#" class="link" @click.prevent="fnRequestTrans('01')">{{ $t('msg.ONEX010T010.090') }}</a></li><!-- 반납지 조회 -->
              <li :class="requestAreaBtn.trans02 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestTrans('02')">{{ $t('msg.ONEX010T010.091') }}</a></li><!-- 긴급양하 신청 -->
            </ul>
          </div>
          <div class="item case2">
            <p>{{ $t('msg.ONEX010T010.037') }}</p><!-- 각종 출력 -->
            <ul>
              <li :class="requestAreaBtn.report01 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('01')">{{ $t('msg.ONEX010T010.040') }}</a></li><!-- DELAY NOTICE -->
              <li :class="requestAreaBtn.report02 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('02')">{{ $t('msg.ONEX010T010.044') }}</a></li><!-- B/L COPY -->
              <!--<li class="disabled"><a href="#" class="pop">Delay Notice</a></li>-->
              <li :class="requestAreaBtn.report03 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('03')">{{ $t('msg.ONEX010T010.039') }}</a></li><!-- INVOICE -->
              <template v-if="memberDetail.userCtrCd !== 'CN'">
                <li :class="requestAreaBtn.report04 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('04')">{{ $t('msg.ONEX010T010.059') }}</a></li><!-- A/N -->
              </template>
              <template v-if="memberDetail.userCtrCd === 'KR'">
                <li :class="requestAreaBtn.report05 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('05')">{{ $t('msg.ONEX010T010.080') }}</a></li><!-- 적하목록, ETB -->
              </template>
              <li :class="requestAreaBtn.report06 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('06')">{{ $t('msg.ONEX010T010.060') }}</a></li><!-- D/O -->
              <li :class="requestAreaBtn.report07 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('07')">{{ $t('msg.ONEX010T010.041') }}</a></li><!-- Freetime 승인 내역 -->
              <li v-if="memberDetail.userCtrCd === 'ID'" :class="requestAreaBtn.report08 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('08')">{{ $t('msg.ONEX010T010.092') }}</a></li><!-- RECEIPT -->
              <li :class="requestAreaBtn.report09 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('09')">{{ $t('msg.ONEX010T010.159') }}</a></li><!-- 관리대상 -->
              <li v-if="memberDetail.userCtrCd === 'KR'" :class="requestAreaBtn.report10 === 'Y' ? '' : 'disabled'"><a href="#" class="pop" @click.prevent="fnRequestReport('10')">{{ $t('msg.ONEX010T010.161') }}</a></li><!-- CNTR 개별중량 조회 -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <win-layer-pop class="sample_popup_list">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closePopup"
        :parent-info="infoData"
      />
    </win-layer-pop>
    <win-layer-pop class="import_ongoing_list_popup_tp2">
      <component
        v-if="popupTp2Component"
        :is="popupTp2Component"
        @close="closeTp2Popup"
        :ok-hidden="isHidden"
        :is-show-pop="isShowPop"
        :detail="popupTp2Params"
      />
    </win-layer-pop>
    <win-layer-pop class="import_ongoing_list_popup_tp3">
      <component
        v-if="popupTp3Component"
        :is="popupTp3Component"
        @close="closeTp3Popup"
        @callback="popupTp3Callback"
        :parent-info="popupTp3Params"
      />
    </win-layer-pop>
    <common-unsolved ref="commonUnsolved" @callback="searchAct" />
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import importSvc from '@/api/rest/trans/importSvc'
import exportSvc from '@/api/rest/trans/exportSvc'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import doapi from '@/api/rest/trans/do'
import schedule from '@/api/rest/schedule/schedule'
import blDoPayment from '@/api/rest/trans/blDoPayment'
import payment from '@/api/rest/trans/payment'
import CalcIssue from '@/api/rest/settle/calcIssue'
import JSZip from 'jszip'
import moment from 'moment'
import commons from '@/api/services/commons'

let gridViewIm = GridView
let providerIm = LocalDataProvider

const convertDate = (strDt) => {
  if (strDt === undefined || strDt === null || strDt === '') {
    return ''
  }
  const month = {
    K01: 'JAN',
    K02: 'FEB',
    K03: 'MAR',
    K04: 'APR',
    K05: 'MAY',
    K06: 'JUN',
    K07: 'JUL',
    K08: 'AUG',
    K09: 'SEP',
    K10: 'OCT',
    K11: 'NOV',
    K12: 'DEC'
  }
  const key = 'K' + strDt.substring(4, 6)
  const m = month[key] || ''
  const d = strDt.substring(6, 8)
  const t = strDt.substring(8, 10)
  return `${m} ${d} ${t}H`
}

const fieldsIm = [
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'blStsCd', dataType: 'text' },
  { fieldName: 'actShprCstEnm', dataType: 'text' },
  { fieldName: 'cneCstEnm', dataType: 'text' },
  { fieldName: 'shipper', dataType: 'text' },
  { fieldName: 'por', dataType: 'text' },
  { fieldName: 'dly', dataType: 'text' },
  { fieldName: 'etd', dataType: 'text' },
  { fieldName: 'etdDiff', dataType: 'text' },
  { fieldName: 'eta', dataType: 'text' },
  { fieldName: 'etaDiff', dataType: 'text' },
  { fieldName: 'statusCd', dataType: 'text' },
  { fieldName: 'polCtrCd', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podCtrCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'polTrmlCd', dataType: 'text' },
  { fieldName: 'podTrmlCd', dataType: 'text' },
  { fieldName: 'vslCd', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'depVslNm', dataType: 'text' },
  { fieldName: 'arvVslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'cntrQty', dataType: 'text' },
  { fieldName: 'cntrCnt', dataType: 'text' },
  { fieldName: 'cargo', dataType: 'text' },
  { fieldName: 'log', dataType: 'text' },
  { fieldName: 'tel', dataType: 'text' },
  { fieldName: 'cntrDis', dataType: 'text' },
  { fieldName: 'cntrGto', dataType: 'text' },
  { fieldName: 'cntrRtn', dataType: 'text' },
  { fieldName: 'trmlCd', dataType: 'text' },
  { fieldName: 'trmlEnm', dataType: 'text' },
  { fieldName: 'cyEnm', dataType: 'text' },
  { fieldName: 'trmlUrl', dataType: 'text' },
  { fieldName: 'inspecCatNm', dataType: 'text' },
  { fieldName: 'inspecCatCd', dataType: 'text' },
  { fieldName: 'rvsdEndpYn', dataType: 'text' },
  { fieldName: 'msnNo', dataType: 'text' },
  { fieldName: 'mrnNo', dataType: 'text' },
  { fieldName: 'rteCd', dataType: 'text' },
  { fieldName: 'anChk', dataType: 'text' },
  { fieldName: 'mfYn', dataType: 'text' },
  { fieldName: 'salesEmail', dataType: 'text' },
  { fieldName: 'frtYn', dataType: 'text' },
  { fieldName: 'frtInfo', dataType: 'text' },
  { fieldName: 'acshRnoStr', dataType: 'text' },
  { fieldName: 'doCheck', dataType: 'text' },
  { fieldName: 'reqPicNm', dataType: 'text' },
  { fieldName: 'reqRsps', dataType: 'text' },
  { fieldName: 'payNo', dataType: 'text' },
  { fieldName: 'evdcmtrUpldYn', dataType: 'text' },
  { fieldName: 'payStsCd', dataType: 'text' },
  { fieldName: 'rmk', dataType: 'text' },
  { fieldName: 'idStatus', dataType: 'text' },
  { fieldName: 'idRemark', dataType: 'text' },
  { fieldName: 'hzYn', dataType: 'text' },
  { fieldName: 'seeYn', dataType: 'text' },
  { fieldName: 'closeInfo', dataType: 'text' },
  { fieldName: 'iotCntrCnt', dataType: 'Number' },
  { fieldName: 'dg127Yn', dataType: 'text' }
]

const defCol1Im = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: app.$t('msg.ONEX010T010.046') + '\n(' + app.$t('msg.ONEX010T010.093') + ')' }, // B/L No.\n(Status)
    width: '85',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const blNo = grid.getValue(idx, 'blNo')
        // const blStsCd = grid.getValue(idx, 'blStsCd')
        const blNo = vmAppIm.gridList[idx].blNo
        const blStsCd = vmAppIm.gridList[idx].blStsCd
        const podCtrCd = vmAppIm.gridList[idx].podCtrCd
        const shipper = vmAppIm.gridList[idx].shipper

        // 부킹 승인 전 또는 S/R Create 전 일 때 (공란)
        if (blNo === null || blNo === undefined || blNo === '') {
          return ''
        }

        const html = []
        html.push(`<span><a onclick="vmAppIm.unipassByBlNo('${blNo}');">${blNo}</a></span>`)
        if (blStsCd === 'A1') {
          html.push(`<span class="label2 blue">${app.$t('msg.ONIM050G010.011')}</span>`) // O.B/L 접수
        } else if (blStsCd === 'A2') {
          html.push(`<span class="label2 blue">${app.$t('msg.ONIM010T010.016')}</span>`) // Bank L/G 접수
        } else if (blStsCd === 'A3') {
          html.push(`<span class="label2 blue"><a onclick="vmAppIm.surrenderNoticePop('${blNo}', '${shipper}');">${app.$t('msg.ONIM050G010.013')}</a></span>`) // SURRENDER B/L
        } else if (blStsCd === 'A4') {
          html.push(`<span class="label2 blue"><a onclick="vmAppIm.seawaybillNoticePop('${blNo}', '${shipper}');">${app.$t('msg.ONIM050G010.014')}</a></span>`) // WAYBILL
        } else if (blStsCd === 'A9' && podCtrCd === 'KR') {
          html.push(`<span class="label2 blue">${app.$t('msg.ONIM099P010.062')}</span>`) // 추후보완
        } else if (blStsCd === 'A6') {
          html.push(`<span class="label2 blue">${app.$t('msg.ONIM099P010.029')}</span>`) // B/L 미발행
        } else {
          html.push(`<span class="label2 blue">${app.$t('msg.ONIM099P010.033')}</span>`) // B/L 미접수
        }

        return html.join('<br/>')
      }
    }
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'shipper',
    header: { text: app.$t('msg.VOSD100_M5.112') }, // 실화주
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const id = grid.getValue(idx, 'blNo')
        // const shipper = grid.getValue(idx, 'shipper')
        // const cneCstEnm = grid.getValue(idx, 'cneCstEnm')
        const id = vmAppIm.gridList[idx].blNo
        const shipper = vmAppIm.gridList[idx].shipper
        const cneCstEnm = vmAppIm.gridList[idx].cneCstEnm
        const arrTooltip = []
        let showToolTip = false
        if (cneCstEnm.indexOf('TO THE ORDER') > -1 ||
            cneCstEnm.indexOf('TO ORDER') > -1 ||
            cneCstEnm.indexOf('ORDER OF') > -1 ||
            cneCstEnm.indexOf('MADE OUT OF') > -1) {
          if (shipper !== '**') {
            arrTooltip.push(cneCstEnm)
            showToolTip = true
          }
        }
        if (showToolTip) {
          return `<div id="shipper_btn_${id}" onmouseover="vmAppIm.showTooltip(event, '${id}', 'shipper')" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'shipper')">${shipper}</div>
          <div id="shipper_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'shipper')" class="tooltip_wrap short position_absolute" style="width:300px; display:none; left:-50px; top:30px;"><div class="cont"><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('')}</li></ul></div></div>`
        } else {
          return shipper
        }
      }
    }
  },
  {
    name: 'trmlEnm',
    fieldName: 'cyEnm',
    header: { text: app.$t('msg.ONEX010T010.144') }, // 접안부두(입항터미널)
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const trmlEnm = grid.getValue(idx, 'cyEnm')
        // const trmlUrl = grid.getValue(idx, 'trmlUrl')
        const trmlEnm = vmAppIm.gridList[idx].cyEnm
        const trmlUrl = vmAppIm.gridList[idx].trmlUrl
        if (trmlUrl !== undefined) {
          return `<a href="${trmlUrl}" target="_blank">${trmlEnm}</a>`
        } else {
          return trmlEnm
        }
      }
    }
  },
  {
    name: 'inspecCatNm',
    fieldName: 'inspecCatNm',
    header: { text: app.$t('msg.ONEX010T010.086') }, // 관리대상 지정여부
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const inspecCatCd = grid.getValue(idx, 'inspecCatCd')
        // const inspecCatNm = grid.getValue(idx, 'inspecCatNm')
        //const blNo = grid.getValue(idx, 'blNo')
        const inspecCatCd = vmAppIm.gridList[idx].inspecCatCd
        const inspecCatNm = vmAppIm.gridList[idx].inspecCatNm
        const blNo = vmAppIm.gridList[idx].blNo
        if (inspecCatCd !== undefined) {
          return `<a onclick="vmAppIm.fnInsPecPop('${blNo}');">${inspecCatNm}</a>`
        } else {
          return 'N'
        }
      }
    }
  },
  {
    name: 'closeInfo',
    fieldName: 'closeInfo',
    header: { text: app.$t('msg.ONEX010T010.085') }, // 마감정보
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmAppIm.gridList[idx].blNo || ''
        const podPortCd = vmAppIm.gridList[idx].podPortCd
        const podCtrCd = vmAppIm.gridList[idx].podCtrCd
        const vslCd = vmAppIm.gridList[idx].vslCd
        const podTrmlCd = vmAppIm.gridList[idx].podTrmlCd

        return `<button type="button" class="tbl_icon tel" onclick="vmAppIm.importManagerPopCall('${blNo}', '${podPortCd}', '${podCtrCd}', '${vslCd}', '${podTrmlCd}');">연락처</button>`
      }
    }
  },
  {
    name: 'polPortCd',
    fieldName: 'polPortCd',
    header: { text: app.$t('msg.ONEX010T010.049') }, // 출발지 ETD
    width: '98',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const por = grid.getValue(idx, 'por')
        // const etd = grid.getValue(idx, 'etd')
        // const diff = grid.getValue(idx, 'etdDiff')
        const por = vmAppIm.gridList[idx].por
        const etd = vmAppIm.gridList[idx].etd
        const diff = vmAppIm.gridList[idx].etdDiff
        const etdText = convertDate(etd)
        // popup
        // const blNo = grid.getValue(idx, 'blNo')
        // const polPortNm = grid.getValue(idx, 'polPortNm')
        // const podPortNm = grid.getValue(idx, 'podPortNm')
        // const polPortCd = grid.getValue(idx, 'polPortCd')
        // const podPortCd = grid.getValue(idx, 'podPortCd')
        // const vslCd = grid.getValue(idx, 'vslCd')
        // const voyNo = grid.getValue(idx, 'voyNo')
        const blNo = vmAppIm.gridList[idx].blNo
        const polPortNm = vmAppIm.gridList[idx].polPortNm
        const podPortNm = vmAppIm.gridList[idx].podPortNm
        const polPortCd = vmAppIm.gridList[idx].polPortCd
        const podPortCd = vmAppIm.gridList[idx].podPortCd
        const vslCd = vmAppIm.gridList[idx].vslCd
        const voyNo = vmAppIm.gridList[idx].voyNo
        if (diff !== undefined) {
          if (diff !== '0' && diff.indexOf('-') === -1) {
            return `<a onclick="vmAppIm.popPorDlySchdule('${blNo}','${polPortNm}','${podPortNm}','${polPortCd}','${podPortCd}','${vslCd}','${voyNo}');">${por} <br><span class="font_11">${etdText} (+${diff})</span></a>`
          } else {
            return `<a onclick="vmAppIm.popPorDlySchdule('${blNo}','${polPortNm}','${podPortNm}','${polPortCd}','${podPortCd}','${vslCd}','${voyNo}');">${por} <br><span class="font_11">${etdText} (${diff})</span></a>`
          }
        } else {
          return `<a onclick="vmAppIm.popPorDlySchdule('${blNo}','${polPortNm}','${podPortNm}','${polPortCd}','${podPortCd}','${vslCd}','${voyNo}');">${por} <br><span class="font_11">${etdText}</span></a>`
        }
      }
    }
  },
  {
    name: 'podPortCd',
    fieldName: 'podPortCd',
    header: { text: app.$t('msg.ONEX010T010.050') }, // 도착지 ETA
    width: '97',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const dly = grid.getValue(idx, 'dly')
        // const eta = grid.getValue(idx, 'eta')
        // const diff = grid.getValue(idx, 'etaDiff')
        const dly = vmAppIm.gridList[idx].dly
        const eta = vmAppIm.gridList[idx].eta
        const diff = vmAppIm.gridList[idx].etaDiff
        const etaText = convertDate(eta)
        // popup
        // const blNo = grid.getValue(idx, 'blNo')
        // const polPortNm = grid.getValue(idx, 'polPortNm')
        // const podPortNm = grid.getValue(idx, 'podPortNm')
        // const polPortCd = grid.getValue(idx, 'polPortCd')
        // const podPortCd = grid.getValue(idx, 'podPortCd')
        // const vslCd = grid.getValue(idx, 'vslCd')
        // const voyNo = grid.getValue(idx, 'voyNo')
        const blNo = vmAppIm.gridList[idx].blNo
        const polPortNm = vmAppIm.gridList[idx].polPortNm
        const podPortNm = vmAppIm.gridList[idx].podPortNm
        const polPortCd = vmAppIm.gridList[idx].polPortCd
        const podPortCd = vmAppIm.gridList[idx].podPortCd
        const vslCd = vmAppIm.gridList[idx].vslCd
        const voyNo = vmAppIm.gridList[idx].voyNo
        if (diff !== undefined) {
          if (diff !== '0' && diff.indexOf('-') === -1) {
            return `<a onclick="vmAppIm.popPorDlySchdule('${blNo}','${polPortNm}','${podPortNm}','${polPortCd}','${podPortCd}','${vslCd}','${voyNo}');">${dly} <br><span class="font_11">${etaText} (+${diff})</span></a>`
          } else {
            return `<a onclick="vmAppIm.popPorDlySchdule('${blNo}','${polPortNm}','${podPortNm}','${polPortCd}','${podPortCd}','${vslCd}','${voyNo}');">${dly} <br><span class="font_11">${etaText} (${diff})</span></a>`
          }
        }
      }
    }
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    header: { text: app.$t('msg.ONEX010T010.051') }, // VSL/VOY
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const vslNm = grid.getValue(idx, 'vslNm')
        // const voyNo = grid.getValue(idx, 'voyNo')
        // const blNo = grid.getValue(idx, 'blNo')
        const vslNm = vmAppIm.gridList[idx].vslNm
        const voyNo = vmAppIm.gridList[idx].voyNo
        const blNo = vmAppIm.gridList[idx].blNo
        const podCtrCd = vmAppIm.gridList[idx].podCtrCd
        const id = blNo

        if (podCtrCd === 'KR') {
          return `<span id="vslNm_btn_${id}"
                onmouseover="vmAppIm.showTooltipVslNm(event, '${id}', 'vslNm')"
                onmouseout="vmAppIm.hideTooltip(event, '${id}', 'vslNm')"
                onclick="vmAppIm.popVslSchedule('${blNo}');">${vslNm}<br>${voyNo}</span></br>
                <div id="vslNm_tt_${id}"
                  onmouseout="vmAppIm.hideTooltip(event, '${id}', 'vslNm')"
                  class="tooltip_wrap position_absolute vslNm_tt" style="width:188px; left:0px; display:none; top:30px; word-break: break-all;">
                  <div class="cont">
                    <ul class="bul_list_sm t2">
                      <li class="text_left tt_cont"></li>
                    </ul>
                  </div>
                </div>`
        } else {
          return `<a style='cursor: pointer' onclick="vmAppIm.popVslSchedule('${blNo}');">${vslNm}<br>${voyNo}</a>`
        }
      }
    }
  },
  {
    name: 'depVslNm',
    fieldName: 'depVslNm',
    header: { text: app.$t('msg.ONEX010T010.163') }, // VSL/VOY
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const vslNm = grid.getValue(idx, 'vslNm')
        // const voyNo = grid.getValue(idx, 'voyNo')
        // const blNo = grid.getValue(idx, 'blNo')
        const vslNm = vmAppIm.gridList[idx].depVslNm
        const voyNo = vmAppIm.gridList[idx].depVoyNo
        const blNo = vmAppIm.gridList[idx].blNo
        const podCtrCd = vmAppIm.gridList[idx].depPodCtrCd
        const id = blNo

        if (podCtrCd === 'KR') {
          return `<span id="depVslNm_btn_${id}"
                onmouseover="vmAppIm.showTooltipVslNm(event, '${id}', 'depVslNm')"
                onmouseout="vmAppIm.hideTooltip(event, '${id}', 'depVslNm')"
                onclick="vmAppIm.popVslSchedule('${blNo}','1');">${vslNm}<br>${voyNo}</span></br>
                <div id="depVslNm_tt_${id}"
                  onmouseout="vmAppIm.hideTooltip(event, '${id}', 'depVslNm')"
                  class="tooltip_wrap position_absolute vslNm_tt" style="width:188px; left:0px; display:none; top:30px; word-break: break-all;">
                  <div class="cont">
                    <ul class="bul_list_sm t2">
                      <li class="text_left tt_cont"></li>
                    </ul>
                  </div>
                </div>`
        } else {
          return `<a style='cursor: pointer' onclick="vmAppIm.popVslSchedule('${blNo}','1');">${vslNm}<br>${voyNo}</a>`
        }
      }
    }
  },
  {
    name: 'arvVslNm',
    fieldName: 'arvVslNm',
    header: { text: app.$t('msg.ONEX010T010.164') }, // VSL/VOY
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const vslNm = grid.getValue(idx, 'vslNm')
        // const voyNo = grid.getValue(idx, 'voyNo')
        // const blNo = grid.getValue(idx, 'blNo')
        const vslNm = vmAppIm.gridList[idx].vslNm
        const voyNo = vmAppIm.gridList[idx].voyNo
        const blNo = vmAppIm.gridList[idx].blNo
        const podCtrCd = vmAppIm.gridList[idx].podCtrCd
        const id = blNo

        if (podCtrCd === 'KR') {
          return `<span id="vslNm_btn_${id}"
                onmouseover="vmAppIm.showTooltipVslNm(event, '${id}', 'vslNm')"
                onmouseout="vmAppIm.hideTooltip(event, '${id}', 'vslNm')"
                onclick="vmAppIm.popVslSchedule('${blNo}','2');">${vslNm}<br>${voyNo}</span></br>
                <div id="vslNm_tt_${id}"
                  onmouseout="vmAppIm.hideTooltip(event, '${id}', 'vslNm')"
                  class="tooltip_wrap position_absolute vslNm_tt" style="width:188px; left:0px; display:none; top:30px; word-break: break-all;">
                  <div class="cont">
                    <ul class="bul_list_sm t2">
                      <li class="text_left tt_cont"></li>
                    </ul>
                  </div>
                </div>`
        } else {
          return `<a style='cursor: pointer' onclick="vmAppIm.popVslSchedule('${blNo}','2');">${vslNm}<br>${voyNo}</a>`
        }
      }
    }
  },
  {
    name: 'cntrCnt',
    fieldName: 'cntrCnt',
    header: { text: app.$t('msg.ONEX010T010.052') }, // CTNR
    width: '80',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cntrCnt = vmAppIm.gridList[idx].cntrCnt
        const cntrQty = vmAppIm.gridList[idx].cntrQty
        const blNo = vmAppIm.gridList[idx].blNo
        const hzYn = vmAppIm.gridList[idx].hzYn
        const iotCntrCnt = vmAppIm.gridList[idx].iotCntrCnt || ''
        const dg127Yn = vmAppIm.gridList[idx].dg127Yn

        let aTag
        let iotBtn

        // console.log('iotCntrCnt @@@@ ', iotCntrCnt)

        if (cntrCnt) {
          if (cntrCnt.indexOf('HZ') > -1) {
            aTag = `<a onClick="vmAppIm.fnCgoTypPop('${blNo}','HZ')">${cntrQty.split(',').join('<br/>')}</a>`
          } else if (cntrCnt.indexOf('OT') > -1) {
            aTag = `<a onClick="vmAppIm.fnCgoTypPop('${blNo}','OT')">${cntrQty.split(',').join('<br/>')}</a>`
          } else if (cntrCnt.indexOf('FR') > -1) {
            aTag = `<a onClick="vmAppIm.fnCgoTypPop('${blNo}','FR')">${cntrQty.split(',').join('<br/>')}</a>`
          } else if (cntrCnt.indexOf('RH') > -1) {
            aTag = `<a onClick="vmAppIm.fnCgoTypPop('${blNo}','RH')">${cntrQty.split(',').join('<br/>')}</a>`
          } else if (cntrCnt.indexOf('RF') > -1) {
            aTag = `<a onClick="vmAppIm.fnCgoTypPop('${blNo}','RF')">${cntrQty.split(',').join('<br/>')}</a>`
          } else {
            aTag = cntrQty.split(',').join('<br/>')
          }
        } else {
          aTag = cntrQty.split(',').join('<br/>')
        }
        if (hzYn === 'Y') {
          aTag += '<br/><span class="color_blue2 font_11">HZ</span>'
        }

        if (dg127Yn === 'Y' && vmAppIm.memberDetail.userCtrCd === 'KR') {
          aTag += '<br/><span class="color_red font_11">직반출대상</span>'
        }

        if (blNo !== null && blNo !== '' && iotCntrCnt > 0) {
          iotBtn = `<br/><a class="button sm" onclick="vmAppIm.openRfDataInfoPop('${blNo}')">IoT</a>`
        } else {
          iotBtn = ''
        }

        return aTag + iotBtn
      }
    }
  },
  { name: 'cargo', fieldName: 'cargo', header: { text: 'CARGO' }, width: '70' }
]

const columnDocIm = [
  {
    name: 'anChk',
    fieldName: 'anChk',
    header: { text: app.$t('msg.ONEX010T010.059') }, // A/N
    width: '90',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const rvsdEndpYn = grid.getValue(idx, 'rvsdEndpYn')
        // const anChk = grid.getValue(idx, 'anChk')
        // const blNo = grid.getValue(idx, 'blNo')
        const rvsdEndpYn = vmAppIm.gridList[idx].rvsdEndpYn
        const anChk = vmAppIm.gridList[idx].anChk
        const blNo = vmAppIm.gridList[idx].blNo
        const id = blNo
        const arrTooltip = []
        // 선박 출항 전 (공란으로 표시)
        // if (rvsdEndpYn === 'N' || (anChk !== 'N' && anChk !== 'Y')) {
        if (rvsdEndpYn === 'N') {
          return ''
        }
        let cssClasss = ''
        let btnText = ''
        let onMouseOverBtnEvt = `onmouseover="vmAppIm.showTooltip(event, '${id}', 'anChk')"` // 버튼 툴팁관련 이벤트
        let onMouseOutBtnEvt = `onmouseout="vmAppIm.hideTooltip(event, '${id}', 'anChk')"` // 버튼 툴팁관련 이벤트
        if (vmAppIm.memberDetail.userCtrCd === 'CN') {
          if (anChk === 'Y') {
            cssClasss = 'finish'
            btnText = app.$t('msg.ONEX010T010.131')
            arrTooltip.push(app.$t('msg.ONEX010T010.076')) // A/N 이 발송/출력되었습니다.
          } else {
            cssClasss = 'uncom'
            btnText = app.$t('msg.ONEX010T010.124')
            arrTooltip.push(app.$t('msg.ONEX010T010.077')) // A/N 이 발송/출력되지 않았습니다.
          }
          // 중국은 툴팁 숨김 처리
          onMouseOverBtnEvt = ''
          onMouseOutBtnEvt = ''
        } else {
          arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickAnChk(event, '${blNo}')">`)
          if (anChk === 'Y') {
            cssClasss = 'finish'
            btnText = app.$t('msg.ONEX010T010.131')
            arrTooltip.push(app.$t('msg.ONEX010T010.076')) // A/N 이 발송/출력되었습니다.
          } else {
            cssClasss = 'uncom'
            btnText = app.$t('msg.ONEX010T010.124')
            arrTooltip.push(app.$t('msg.ONEX010T010.077')) // A/N 이 발송/출력되지 않았습니다.
          }
          arrTooltip.push('</a>')
        }

        return `<button type="button" id="anChk_btn_${id}"
        class="status_icon ${cssClasss}" ${onMouseOverBtnEvt} ${onMouseOutBtnEvt}>${btnText}</button>
        <br/>
        <div id="anChk_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'anChk')"
        class="tooltip_wrap short position_absolute"
        style="width:250px; display:none; left:-100px; top:30px;">
        <div class="cont"><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('')}</li></ul></div></div>`
      }
    }
  },
  {
    name: 'mfYn',
    fieldName: 'mfYn',
    header: { text: app.$t('msg.ONEX010T010.080') }, // 적하목록
    width: '90',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const rvsdEndpYn = grid.getValue(idx, 'rvsdEndpYn')
        // const frtYn = grid.getValue(idx, 'frtYn')
        // const blNo = grid.getValue(idx, 'blNo')
        // const mfYn = grid.getValue(idx, 'mfYn')
        // const vslCd = grid.getValue(idx, 'vslCd')
        // const voyNo = grid.getValue(idx, 'voyNo')
        const rvsdEndpYn = vmAppIm.gridList[idx].rvsdEndpYn
        const frtYn = vmAppIm.gridList[idx].frtYn
        const blNo = vmAppIm.gridList[idx].blNo
        const mfYn = vmAppIm.gridList[idx].mfYn
        const vslCd = vmAppIm.gridList[idx].vslCd
        const voyNo = vmAppIm.gridList[idx].voyNo
        const seeYn = vmAppIm.gridList[idx].seeYn
        const id = blNo
        // 선박 출항 전 (공란으로 표시)
        if (rvsdEndpYn === 'N' || (frtYn !== 'E' && frtYn !== 'N' && frtYn !== 'Y')) {
          return ''
        }
        const cssClasss = mfYn === 'Y' ? 'finish' : 'uncom'
        const btnText = mfYn === 'Y' ? app.$t('msg.ONEX010T010.131') : app.$t('msg.ONEX010T010.124')

        if (vmAppIm.memberDetail.userCtrCd === 'KR') {
          const arrTooltip = []
          if (mfYn === 'Y') {
            arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickMfYn(event, '${blNo}', '${vslCd}', '${voyNo}', '${seeYn}')">${app.$t('msg.ONEX010T010.081')}</a>`) // 입항적하목록이 제출되었습니다.
          } else {
            arrTooltip.push(`${app.$t('msg.ONEX010T010.082')}`) // 입항적하목록이 제출되지 않았습니다.
          }
          return `<button type="button" id="mfYn_btn_${id}" class="status_icon ${cssClasss}" onmouseover="vmAppIm.showTooltip(event, '${id}', 'mfYn')" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'mfYn')">${btnText}</button><br/><div id="mfYn_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'mfYn')" class="tooltip_wrap position_absolute" style="width:250px; display:none; left:-100px; top:30px;"><div class="cont text_left"><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}</li></ul></div></div>`
        } else {
          return `<button type="button" id="mfYn_btn_${id}" class="status_icon ${cssClasss}">${btnText}</button>`
        }
      }
    }
  },
  {
    name: 'frtYn',
    fieldName: 'frtYn',
    header: { text: app.$t('msg.ONEX010T010.057') }, // 운임
    width: '90',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        // const rowData = grid.getValues(idx)
        // const rvsdEndpYn = grid.getValue(idx, 'rvsdEndpYn')
        // const frtYn = grid.getValue(idx, 'frtYn')
        // const frtInfo = grid.getValue(idx, 'frtInfo')
        const rowData = vmAppIm.gridList[idx]
        const rvsdEndpYn = vmAppIm.gridList[idx].rvsdEndpYn
        const frtYn = vmAppIm.gridList[idx].frtYn
        const frtInfo = vmAppIm.gridList[idx].frtInfo
        const frtAmtAuth = vmAppIm.gridList[idx].frtAmtAuth
        let frtInfoArr = []
        if (frtInfo !== undefined) {
          frtInfoArr = frtInfo.split('/')
        }
        // const blNo = grid.getValue(idx, 'blNo')
        // const payNo = grid.getValue(idx, 'payNo') // As-is > Pay No
        // const evdcmtrUpldYn = grid.getValue(idx, 'evdcmtrUpldYn') // As-is > Pay Upload
        // const payStsCd = grid.getValue(idx, 'payStsCd') // As-is > Pay Status(01:Request, 02:Accept, 03:Cancel, 04:Reject)
        // const rmk = grid.getValue(idx, 'rmk')
        const blNo = vmAppIm.gridList[idx].blNo
        const payNo = vmAppIm.gridList[idx].payNo // As-is > Pay No
        const evdcmtrUpldYn = vmAppIm.gridList[idx].evdcmtrUpldYn // As-is > Pay Upload
        const payStsCd = vmAppIm.gridList[idx].payStsCd // As-is > Pay Status(01:Request, 02:Accept, 03:Cancel, 04:Reject)
        const rmk = vmAppIm.gridList[idx].rmk
        const id = blNo
        // const polCtrCd = grid.getValue(idx, 'polCtrCd')
        // const podCtrCd = grid.getValue(idx, 'podCtrCd')
        // 선박 출항 전 (공란으로 표시)
        if (rvsdEndpYn === 'N' || (frtYn !== 'E' && frtYn !== 'N' && frtYn !== 'Y')) {
          return ''
        }
        let cssClasss = ''
        let btnText = ''
        const arrTooltip = []
        const arrPayInfo = []
        if (vmAppIm.memberDetail.userCtrCd === 'ID') {
          // 인도네시아
          if (payStsCd === '02') {
            cssClasss = 'finish'
            btnText = app.$t('msg.ONEX010T010.131')
          } else {
            cssClasss = 'uncom'
            btnText = app.$t('msg.ONEX010T010.124')
          }
          if (rmk !== undefined) {
            arrTooltip.push(rmk)
          }
          if (payNo !== undefined) {
            arrPayInfo.push(`<p class="color_blue2 font_11 mt5">Payment No. ('${evdcmtrUpldYn}')</p>`)
          } else {
            arrPayInfo.push('<p class="color_blue2 font_11 mt5">Payment No. (N)</p>')
          }
          if (arrTooltip.length !== 0) {
            return `<button type="button" id="frtYn_btn_${id}" class="status_icon ${cssClasss}" onmouseover="vmAppIm.showTooltip(event, '${id}', 'frtYn')" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'frtYn')">${btnText}</button>`
          } else {
            return `<button type="button" id="frtYn_btn_${id}" class="status_icon ${cssClasss}">${btnText}</button>${arrPayInfo.join('')}`
          }
        } else {
          /* the other contries */
          if (frtYn === 'Y') {
            cssClasss = 'finish'
            btnText = app.$t('msg.ONEX010T010.131')
            // if (frtInfo !== undefined) {
            //   const acshRnoStr = vmAppIm.gridList[idx].acshRnoStr
            //   const acshLen = acshRnoStr ? acshRnoStr.split(',').length - 1 : 0
            //   arrTooltip.push(`${app.$t('msg.ONIM010T010.001')} : ` + frtInfoArr[1] + (acshLen > 0 ? `외 ${acshLen}개` : '')) //세금계산서 번호
            //   arrTooltip.push(`${app.$t('msg.CSDBL400_M2.0042')} : ` + vmAppIm.$ekmtcCommon.changeNumberFormat(frtInfoArr[0], { isComma: true }) + app.$t('msg.ONEX070G100.018')) // 금액
            //   arrTooltip.push(`${app.$t('msg.MAIN100.206')} : ` + frtInfoArr[3]) // 상태
            //   arrTooltip.push(`${app.$t('msg.SETT010T010.031')} ${app.$t('msg.CMBA100.00163')} : ` + frtInfoArr[2]) // 발급 e-mail
            // }
          } else if (frtYn === 'N') {
            cssClasss = 'uncom'
            btnText = app.$t('msg.ONEX010T010.124')
            // 운임 미정산일 경우 따로 처리
            // arrTooltip.push(`<a href="#" class="link" onclick="vmAppIm.fnClickFrtYn(event, '${blNo}', 'FRT_SUBMIT', '${rowData.polCtrCd}', '${rowData.podCtrCd}')">${app.$t('msg.ONEX010T010.083')}</a>`) // 운임 미정산
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppIm.fnClickFrtAmtAuth(event, '${blNo}', '${frtAmtAuth}')">${app.$t('msg.ONEX010T010.083')}</a>`) // 운임 미정산
          } else if (frtYn === 'E') {
            cssClasss = 'uncom'
            btnText = app.$t('msg.ONEX010T010.124')
            arrTooltip.push(`<a href="#" class="link" onclick="vmAppIm.fnClickFrtYn(event, '${blNo}', 'FRT_REQUEST', '${rowData.polCtrCd}', '${rowData.podCtrCd}')">${app.$t('msg.ONEX010T010.084')}</a>`) // 운임 미등록
          }
          if (frtYn === 'Y' && frtInfo !== undefined) {
            arrTooltip.push(`<a class="link" onclick="vmAppIm.fnInvoicePrintOnGrid('${blNo}')">${app.$t('msg.ONEX010T010.162')}</a>`) // 운임정산완료
            return `<button type="button" id="frtYn_btn_${id}" class="status_icon ${cssClasss}" onmouseover="vmAppIm.showTooltip(event, '${id}', 'frtYn')" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'frtYn')">${btnText}</button><br/><div id="frtYn_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'frtYn')" class="tooltip_wrap position_absolute" style="width:250px; display:none; left:-100px; top:30px;"><div class="cont text_left"><ul class="bul_list_sm t2"><li class="text_left">${arrTooltip.join('<br/>')}</li></ul></div></div>`
          } else {
            return `<button type="button" id="frtYn_btn_${id}" class="status_icon ${cssClasss}">${btnText}</button><br/>${arrTooltip.join('<br/>')}${arrPayInfo.join('')}`
          }
        }
      }
    }
  },
  {
    name: 'doCheck',
    fieldName: 'doCheck',
    header: { text: app.$t('msg.ONEX010T010.060') }, // D/O
    width: '90',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const rowData = vmAppIm.gridList[idx]
        const doChk = vmAppIm.gridList[idx].doCheck
        const reqPicNm = vmAppIm.gridList[idx].reqPicNm
        const reqRsps = vmAppIm.gridList[idx].reqRsps
        const rvsdEndpYn = vmAppIm.gridList[idx].rvsdEndpYn
        const frtYn = vmAppIm.gridList[idx].frtYn
        const id = vmAppIm.gridList[idx].blNo
        const shipper = vmAppIm.gridList[idx].shipper

        // 선박 출항 전 (공란으로 표시)
        if (rvsdEndpYn === 'N' || (frtYn !== 'E' && frtYn !== 'N' && frtYn !== 'Y')) {
          return ''
        }
        let doCheck = ''
        let doStr = ''

        if (doChk !== undefined && doChk.indexOf('/') > -1) {
          doCheck = doChk.substring(0, 1)
          doStr = doChk.substring(2)
        } else {
          doCheck = doChk
        }
        // 리턴값/사유 / 리턴값 : Y 발행가능(V) N 발행불가(X) C 발행완료(신청), I 발행요청(요청)
        let cssClasss = ''
        let cssApplication = 'button sm request' // Application Css
        let btnText = ''
        const arrTooltip = []

        if (doCheck === 'Y') { // Y 발행가능(V)
          cssClasss = cssApplication
          btnText = app.$t('msg.SETT060P040.007')
          // arrTooltip.push('정산/발급화면 이동')
        } else if (doCheck === 'N') { // N 발행불가(X)
          cssClasss = 'uncom'
          btnText = app.$t('msg.ONEX010T010.124')
          if (doStr === 'B/L 미발행') {
            arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">B/L ${app.$t('msg.ONGO080G010.002')}</a>`) // B/L 미발행
          } else if (doStr === 'B/L 미접수') {
            const blStsCd = vmAppIm.gridList[idx].blStsCd
            if (blStsCd === 'A1' || blStsCd === 'A5') {
              arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">${app.$t('msg.ONIM010T010.010')}</a>`) //O.B/L 발행 및 미접수
            } else if (blStsCd === 'A5') {
              cssClasss = cssApplication
              btnText = app.$t('msg.ONIM099P010.010')
            } else if (blStsCd === 'A6') {
              arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">${app.$t('msg.ONIM010T010.009')}</a>`) //B/L 미발행
            } else if (blStsCd === 'A7') {
              arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}', '${blStsCd}')">${app.$t('msg.ONIM050G010.016')}</a>`) // WAY BILL 미발행
            } else if (blStsCd !== 'A3' && blStsCd !== 'A4') { //SURRENDER B/L 발행 아니고 SEA WAYBILL 발행
              arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">B/L ${app.$t('msg.ONIM010T010.002')}</a>`) // B/L 미접수
            }
          } else if (doStr === 'Request' || doStr === 'Pending') {
            cssClasss = 'request'
            if (reqPicNm === 'WEB') {
              arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}','WEB')">D/O ${app.$t('msg.ONIM010T010.003')} (E-KMTC)</a>`) // D/O 발행 중 (E-KMTC)
              arrTooltip.push(`${reqRsps}`)
            } else {
              arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}','${reqPicNm}')">D/O ${app.$t('msg.ONIM010T010.003')} (${reqPicNm.replace(' EDI', '')})</a>`) // D/O 발행 중
              arrTooltip.push(`${reqRsps}`)
            }
          } else if (doStr.indexOf('Reject') > -1) {
            cssClasss = cssApplication
            btnText = app.$t('msg.SETT060P040.007')
            doCheck = 'Y'
            arrTooltip.push(`${reqRsps}`)
          } else {
            arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickFrtYn(event, '${id}', 'FRT_REQUEST', '${rowData.polCtrCd}', '${rowData.podCtrCd}')">${app.$t('msg.ONEX010T010.084')}</a>`) // 운임 미등록(운임 미정산)
          }
        } else if (doCheck === 'C') { // C 발행완료(신청)
          cssClasss = 'finish'
          btnText = app.$t('msg.ONEX010T010.131')
          // if (doStr === 'WEB') {
             arrTooltip.push(`<a class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">${app.$t('msg.ONEX010T010.078')}</a>`)
          // } else {
          //  arrTooltip.push(`<a href="#" class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">${doStr.replace(' EDI', '')}</a>`)
          // }
        } else if (doCheck === 'I') { // I 발행요청(신청)
          cssClasss = 'request'
          btnText = app.$t('msg.ONEX010T010.131')
          // if (doStr === 'WEB') {
            arrTooltip.push(`<a class="link" onclick="vmAppIm.checkDoAuth('${shipper}')">${app.$t('msg.ONEX010T010.087')}</a>`)
          // } else {
          //   arrTooltip.push(`<a href="#" class="link" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">${doStr.replace(' EDI', '')}</a>`)
          // }
        }

        // 한국 인도네시아 일본을 제외한 경우 Application Css대신 X 버튼 Css 적용
        if (cssClasss === cssApplication && ['KR', 'ID', 'JP'].indexOf(vmAppIm.memberDetail.userCtrCd) === -1) {
          cssClasss = 'status_icon uncom'
        }

        let onMouseOverBtnEvt = ''
        let onMouseOutBtnEvt = ''
        // 툴팁을 보여줄 국가(한국,인도네시아,일본)
        if (['KR', 'ID', 'JP'].indexOf(vmAppIm.memberDetail.userCtrCd) >= 0) {
          onMouseOverBtnEvt = `onmouseover="vmAppIm.showTooltip(event, '${id}', '${doCheck}')"`
          onMouseOutBtnEvt = `onmouseout="vmAppIm.hideTooltip(event, '${id}', '${doCheck}')"`
        }
        if (doCheck === 'Y') {
          //발행가능인데 거절사유가 존재하는 경우.
          if (reqRsps && doStr === 'Pending') {
            if (arrTooltip.length === 0) {
              arrTooltip.push(`${reqRsps}`)
            }
            return `<button type="button" id="${doCheck}_btn_${id}" class="status_icon uncom"${onMouseOverBtnEvt}${onMouseOutBtnEvt}>${btnText}</button><br/>${vmAppIm.makeAlert(doCheck, id, arrTooltip, 1)}`
          } else if (doStr.indexOf('Reject') > -1) {
            return `<button type="button" id="${doCheck}_btn_${id}" class="${cssClasss}" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')"${onMouseOverBtnEvt}${onMouseOutBtnEvt}>${btnText}</button><br/>${vmAppIm.makeAlert(doCheck, id, arrTooltip, 1)}`
          } else {
            return `<button type="button" id="${doCheck}_btn_${id}" class="${cssClasss}" onclick="vmAppIm.fnClickDoCheck(event, '${id}', '${doChk}')">${btnText}</button>`
          }
        } else {
          if (arrTooltip.length > 0) {
          return `<button type="button" id="${doCheck}_btn_${id}" class="status_icon ${cssClasss}"${onMouseOverBtnEvt}${onMouseOutBtnEvt}>${btnText}</button><br/>${vmAppIm.makeAlert(doCheck, id, arrTooltip, 2)}`
          } else {
            return `<button type="button" id="${doCheck}_btn_${id}" class="${cssClasss}">${btnText}</button>`
          }
        }
      }
    }
  }
]

const columnConIm = [
  {
    name: 'cntrDis',
    fieldName: 'cntrDis',
    width: '120',
    editable: false,
    header: { text: app.$t('msg.ONEX010T010.064') }, // 양하
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cntrDis = vmAppIm.gridList[idx].cntrDis
        const id = vmAppIm.gridList[idx].blNo
        return `<span id="cntrDis_btn_${id}"onmouseover="vmAppIm.showTooltipCntrInfo(event, '${id}', 'cntrDis', '${id}')"
        onmouseout="vmAppIm.hideTooltip(event, '${id}', 'cntrDis')">${cntrDis}</span><div id="cntrDis_tt_${id}"
        onmouseout="vmAppIm.hideTooltip(event, '${id}', 'cntrDis')"
        class="tooltip_wrap position_absolute cntrDis_tt" style="width:288px; display:none; left:0; top:30px; word-break: break-all;"><div class="cont text_left"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrGto',
    fieldName: 'cntrGto',
    width: '120',
    editable: false,
    header: { text: app.$t('msg.ONEX010T010.065') }, // 반출
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cntrGto = vmAppIm.gridList[idx].cntrGto
        const id = vmAppIm.gridList[idx].blNo
        return `<span id="cntrGto_btn_${id}" onmouseover="vmAppIm.showTooltipCntrInfo(event, '${id}', 'cntrGto', '${id}')" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'cntrGto')">${cntrGto}</span><div id="cntrGto_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'cntrGto')" class="tooltip_wrap position_absolute cntrGto_tt" style="width:300px; display:none; left:-100px; top:30px;"><div class="cont text_left"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  },
  {
    name: 'cntrRtn',
    fieldName: 'cntrRtn',
    width: '120',
    editable: false,
    header: { text: app.$t('msg.ONEX010T010.066') }, // 반납
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const cntrRtn = vmAppIm.gridList[idx].cntrRtn
        const id = vmAppIm.gridList[idx].blNo
        return `<span id="cntrRtn_btn_${id}" onmouseover="vmAppIm.showTooltipCntrInfo(event, '${id}', 'cntrRtn', '${id}')"onmouseout="vmAppIm.hideTooltip(event, '${id}', 'cntrRtn')">${cntrRtn}</span><div id="cntrRtn_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', 'cntrRtn')" class="tooltip_wrap position_absolute cntrRtn_tt" style="width:300px; display:none; left:-200px; top:30px;"><div class="cont text_left"><ul class="bul_list_sm t2"><li class="text_left tt_cont"></li></ul></div></div>`
      }
    }
  }
]

const defCol2 = [
  { name: 'log', fieldName: 'log', header: { text: `${app.$t('msg.ONEX010T070.001')}` }, width: '40' }, // Log 조회
  { name: 'tel', fieldName: 'tel', header: { text: `${app.$t('msg.ONEX010T010.053')}` }, width: '50' } // 담당자\n연락처
]

export const columns1Im = [
  ...defCol1Im,
  ...columnDocIm,
  ...defCol2
]

export const columns2Im = [
  ...defCol1Im,
  ...columnConIm,
  ...defCol2
]

const defLayer1Im = [
  'blNo',
  'actShprCstEnm',
  'polPortCd',
  'podPortCd',
  'vslNm',
  'depVslNm',
  'arvVslNm',
  'cntrCnt',
  'trmlEnm',
  'inspecCatNm',
  'closeInfo'
]

const layerDocIm = [
  {
    name: 'docsIm',
    direction: 'horizontal',
    header: {
      text: `${app.$t('msg.ONIM010T010.004')}`, // 서류 진행 현황
      template: `<ul class="tab_toggle" style="margin-top:2px;"><li><a class="on" onclick="vmAppIm.changeColumn(event, 'doc');">${app.$t('msg.ONEX010T010.054')}</a></li> <!-- 서류 --><li><a onclick="vmAppIm.changeColumn(event, 'cntr');">${app.$t('msg.ONEX010T010.055')}</a></li> <!-- 컨테이너 --></ul>`
    },
    items: ['anChk', 'mfYn', 'frtYn', 'doCheck']
  }
]

const layerConIm = [
{
    name: 'docsIm',
    direction: 'horizontal',
    header: {
      text: `${app.$t('msg.ONIM010T010.005')}`, // 컨테이너 진행 현황
      template: `<ul class="tab_toggle" style="margin-top:2px;"><li><a onclick="vmAppIm.changeColumn(event, 'doc');">${app.$t('msg.ONEX010T010.054')}</a></li> <!-- 서류 --><li><a class="on" onclick="vmAppIm.changeColumn(event, 'cntr');">${app.$t('msg.ONEX010T010.055')}</a></li> <!-- 컨테이너 --></ul>`
    },
    items: [
      'cntrDis',
      'cntrGto',
      'cntrRtn'
    ]
  }
]

const layout1Im = [
  ...defLayer1Im,
  ...layerDocIm
]
const layout2Im = [
  ...defLayer1Im,
  ...layerConIm
]

export default {
  name: 'WorkingProgressImport',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    FareRegisReqPop: () => import('@/pages/trans/popup/FareRegisReqPop'), /* 운임 등록 요청 팝업 */
    VesselScheduleInfoPop: () => import('@/pages/schedule/popup/VesselScheduleInfoPop'), /* 선박정보 및 스케줄 팝업 */
    ImportBLCntrCgoPop: () => import('@/pages/trans/popup/ImportBLCntrCgoPop'), /* 컨테이너정보 팝업 */
    PorDlySchedulePop: () => import('@/pages/trans/popup/PorDlySchedulePop'), /* 출발지/도착지 스케줄 정보 */
    ImportManagerPop: () => import('@/pages/trans/popup/ImportManagerPop'), /* 수입 업무 담당자 팝업 */
    EmergencyShippingReqPop: () => import('@/pages/trans/popup/EmergencyShippingReqPop'), /* 긴급양하 신청 팝업 */
    BLPaymentAccountlessPop: () => import('@/pages/trans/popup/BLPaymentAccountlessPop'), /* 미정산 내역 팝업 */
    PaymentRegPop: () => import('@/pages/trans/popup/PaymentRegPop'), /* Payment 등록 팝업 */
    DelayNoticeInPop: () => import('@/pages/pdf/popup/DelayNoticePop'), /* Delay Notice(수입) 팝업 */
    BlCopyPop: () => import('@/pages/pdf/popup/BlCopyPop'), /* B/L Copy 팝업 */
    CombineInvoicePop: () => import('@/pages/pdf/popup/CombineInvoicePop'), /* combine Invoice */
    BlInvoiceFreightPop: () => import('@/pages/pdf/popup/BlInvoiceFreightPop'), /* Invoice 팝업 */
    ArrivalNoticePop: () => import('@/pages/pdf/popup/ArrivalNoticePop'), /* Arrival Notice 팝업 */
    CargoMainFestPop: () => import('@/pages/pdf/popup/CargoMainFestPop'), /* 적하목록 팝업 */
    DeliveryOrderPop: () => import('@/pages/pdf/popup/DeliveryOrderPop'), /* D/O(Delivery Order) 팝업 */
    FreeTimePop: () => import('@/pages/pdf/popup/FreeTimePop'), /* Free Time 승인 내역 팝업 */
    InspecPrintPop: () => import('@/pages/pdf/popup/InspecPrintPop'), /* 관리 대상 팝업 */
    ReceiptPrintPop: () => import('@/pages/pdf/popup/ReceiptPrintPop'), /* Receipt 팝업 */
    FreightPop: () => import('@/pages/settle/popup/CalcIssueFreightPop'), /* 운임 정정 요청 팝업 */
    FreeTimeExtensReqPop: () => import('@/pages/settle/popup/FreeTimeExtensReqPop'), /* Free Time 연장요청 팝업 */
    DOPaymentPop: () => import('@/pages/trans/popup/DOPaymentPop'), /* D/O발행 팝업 */
    SurrenderReqEmailPop: () => import('@/pages/trans/popup/SurrenderReqEmailPop'), /* 선적지 Surrender 문의 Alert 팝업 */
    CommonUnsolved: () => import('@/pages/trans/CommonUnsolved'),
    SurrenderNoticePop: () => import('@/pages/pdf/popup/SurrenderNoticePop'),
    SeawaybillNoticePop: () => import('@/pages/pdf/popup/SeawaybillNoticePop'),
    RfDataNotiPop: () => import('@/pages/trans/popup/RfDataNotiPop'),
    RfDataInfoPop: () => import('@/pages/trans/popup/RfDataInfoPop'),
    CntrSearchPop: () => import('@/pages/trans/popup/CntrSearchPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      infoData: {},
      searchParams: {},
      txtFilter: '',
      totalCount: 0,
      list: [],
      lang: '',
      customComponent: null,
      tempData: {},
      tempData2: {},
      requestAreaBtn: {},
      checkedCount: 0,
      params: {},
      popupTp2Component: null,
      popupTp2Params: {},
      popupTp3Component: null,
      popupTp3Params: {},
      popupTp3Callback: '',
      isHidden: true,
      isShowPop: true,
      popupParams: {
        VesselScheduleInfoPop: {
          vslNm: '',
          vslCd: '',
          voyNo: '',
          polPortCd: '',
          podPortCd: '',
          bound: '',
          polTrmlCd: ''
        },
        ImportBLCntrCgoPop: {
          data: [],
          flag: ''
        },
        ImportManagerPop: {
          blNo: '',
          podPortCd: ''
        },
        PorDlySchedulePop: {
          bkgNo: '',
          kind: '',
          blNo: '',
          polPortNm: '',
          podPortNm: '',
          polPortCd: '',
          podPortCd: '',
          vslCd: '',
          voyNo: ''
        },
        EmergencyShippingReqPop: {
          blNo: '',
          portCd: '',
          eiCatCd: 'I'
        },
        BLPaymentAccountlessPop: {
          blNo: '',
          list: [],
          eiCatCd: 'I'
        },
        PaymentRegPop: {
          blNo: '',
          linkPayNo: '',
          linkYn: '',
          payStsCd: ''
        },
        DelayNoticeInPop: {
          inOut: 'I',
          items: [
            {
              docNo: '',
              prtGubun: '',
              parm1: ''
            }
          ]
        },
        BlCopyPop: {
          testCtrCd: '',
          testBkgPlcCd: '',
          testUserId: '',
          items: [
            {
              blNo: '',
              porDlyYn: 'N',
              noCnee: 'N',
              noNotify: 'N'
            }
          ]
        },
        BlInvoiceFreightPop: {
          testCtrCd: '',
          testAgtCd: '',
          testBkgPlcCd: '',
          items: [
            {
              invIssNo: '',
              to: '',
              remark: '',
              filter: '',
              blNo: '',
              esign: ''
            }
          ]
        },
        CombineInvoicePop: {
          eiCatCd: 'I',
          items: []
        },
        ArrivalNoticePop: {
          testUsrNo: '',
          testCtrCd: '',
          testBkgPlcCd: '',
          items: [
            {
              blNo: ''
            }
          ]
        },
        CargoMainFestPop: {
          testCtrCd: '',
          items: [
            {
              blNo: '',
              vslCd: '',
              voyNo: ''
            }
          ]
        },
        DeliveryOrderPop: {
          testUsrNo: '',
          testCtrCd: '',
          testAgtCd: '',
          items: [
            {
              blNo: '',
              doRno: ''
            }
          ]
        },
        FreeTimePop: {
          testUsrNo: '',
          testCtrCd: '',
          testBkgPlcCd: '',
          testAgtCd: '',
          items: [
            {
              blNo: ''
            }
          ]
        },
        ReceiptPrintPop: {
          testUsrNo: '',
          testCtrCd: '',
          testAgtCd: '',
          items: [
            {
              rcvRno: '',
              prtRvstNo: '',
              cstNo: '',
              cstNm: ''
            }
          ]
        },
        InspecPrintPop: {
          testCtrCd: '',
          items: [
            {
              blNo: '',
              vslCd: '',
              voyNo: ''
            }
          ]
        },
        FreeTimeExtensReqPop: {},
        DOPaymentPop: {
          blNoArr: []
        },
        SurrenderReqEmailPop: {
          blNo: '',
          voyNo: '',
          vslNm: '',
          podPortCd: '',
          surrEmailyn: '',
          doStsCd: ''
        },
        SurrenderNoticePop: {
          testUsrNo: '',
          items: [
            {
              blNo: ''
            }
          ]
        },
        SeawaybillNoticePop: {
          testUsrNo: '',
          items: [
            {
              blNo: ''
            }
          ]
        },
        CntrSearchPop: {
          blNo: ''
        }
      },
      ctrCdList: [],
      selCtr: '',
      selPo: 'POL',
      relYn: true, //process.env.VUE_APP_MODE !== 'PRD' // JamSin
      reqPixYn: false
      }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this._ = require('lodash')
    this.lang = this.auth.serviceLang

    this.gridHeight = $('body').height() - 400
    if (this.gridHeight < 300) {
      this.gridHeight = 300
    }
    commons.getCommonsCodesCountry().then(res => {
      this.ctrCdList = res
    })
    if (this.$props.parentInfo.workType === 'I') {
      this.search(this.$props.parentInfo)
    }
    if (this.$props.parentInfo != null) {
      this.infoData = this.$props.parentInfo
    }
  },
  mounted () {
    window.vmAppIm = this
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip
    providerIm = new LocalDataProvider(true)
    gridViewIm = new GridView('realgrid')
    gridViewIm.setDataSource(providerIm)
    providerIm.setFields(fieldsIm)
    gridViewIm.setColumnLayout(layout1Im)
    gridViewIm.setColumns(columns1Im)
    gridViewIm.setFooter({ visible: false })
    gridViewIm.setRowIndicator({ visible: false })
    gridViewIm.setStateBar({ visible: false })
    gridViewIm.header.height = 80
    gridViewIm.displayOptions.rowHeight = 70
    gridViewIm.displayOptions.fitStyle = 'evenFill'
    gridViewIm.setCopyOptions({ copyDisplayText: true, lookupDisplay: true, copyTextCallback: this.copyTextCallback })

    this.gridEventsIm()
    this.setRequestArea([])

    if (this.auth.userCtrCd !== 'JP') {
      gridViewIm.columnByName('vslNm').visible = true
      gridViewIm.columnByName('depVslNm').visible = false
      gridViewIm.columnByName('arvVslNm').visible = false
    } else {
      gridViewIm.columnByName('vslNm').visible = false
      gridViewIm.columnByName('depVslNm').visible = true
      gridViewIm.columnByName('arvVslNm').visible = true
    }

    //setTimeout(() => {
    //  this.closeRequestArea()
    //}, 1000)
  },
  methods: {
    showDoUl () {
      let b = false
      if (this.auth.userCtrCd === 'KR') {
        b = true
      } else {
        if (this.auth.userCtrCd === 'JP' || this.auth.userCtrCd === 'ID') {
          b = true
        } else {
          b = false
        }
      }

      return b
    },
    // Ctrl + C 복사 데이터 조합 CallBack (Grid renderer 로직과 같아야 함)
    copyTextCallback (grid, itemIndex, column, value) {
      /*
      // B/L No 복사시 데이터 조합
      if (column.name === 'blNo') {
        var blNo = vmAppIm.gridList[itemIndex].blNo
        var blStsCd = vmAppIm.gridList[itemIndex].blStsCd

        if (blStsCd === 'A1') {
          blNo += '\n' + this.$t('msg.ONIM050G010.011')
        } else if (blStsCd === 'A2') {
          blNo += '\n' + this.$t('msg.ONIM050G010.016')
        } else if (blStsCd === 'A3') {
          blNo += '\n' + this.$t('msg.ONIM050G010.013')
        } else if (blStsCd === 'A4') {
          blNo += '\n' + this.$t('msg.ONIM050G010.014')
        } else {
          blNo += '\n' + this.$t('msg.ONIM099P010.033')
        }
        return blNo
      } */

      // 출발지 ETD 복사시 데이터 조합
      if (column.name === 'polPortCd') {
        var por = vmAppIm.gridList[itemIndex].por
        var etd = vmAppIm.gridList[itemIndex].etd
        var etdDiff = vmAppIm.gridList[itemIndex].etdDiff
        var etdText = convertDate(etd)

        if (etdDiff !== undefined) {
          if (etdDiff !== '0' && etdDiff.indexOf('-') === -1) {
            return por + '\n' + etdText + ' ' + '(+' + etdDiff + ')'
          } else {
            return por + '\n' + etdText + ' ' + '(' + etdDiff + ')'
          }
        } else {
          return por + '\n' + etdText
        }
      }

      // 도착지 ETA 복사시 데이터 조합
      if (column.name === 'podPortCd') {
        var dly = vmAppIm.gridList[itemIndex].dly
        var eta = vmAppIm.gridList[itemIndex].eta
        var etaDiff = vmAppIm.gridList[itemIndex].etaDiff
        var etaText = convertDate(eta)

        if (etaDiff !== undefined) {
          if (etaDiff !== '0' && etaDiff.indexOf('-') === -1) {
            return dly + '\n' + etaText + ' ' + '(+' + etaDiff + ')'
          } else {
            return dly + '\n' + etaText + ' ' + '(' + etaDiff + ')'
          }
        } else {
          return dly + '\n' + etaText
        }
      }

      // 컨테이너 복사시 데이터 조합
      if (column.name === 'cntrCnt') {
        var cntrQty = vmAppIm.gridList[itemIndex].cntrQty
        var hzYn = vmAppIm.gridList[itemIndex].hzYn

        if (hzYn === 'Y') {
          return cntrQty + '\n' + 'HZ'
        } else {
          return cntrQty
        }
      }

      // 관리대상 지정여부 복사시 데이터 조합
      if (column.name === 'inspecCatNm') {
        var inspecCatCd = vmAppIm.gridList[itemIndex].inspecCatCd
        var inspecCatNm = vmAppIm.gridList[itemIndex].inspecCatNm

        if (inspecCatCd !== undefined) {
          return inspecCatNm
        } else {
          return 'N'
        }
      }

      // 마감정보 복사시 데이터 조합
      if (column.name === 'closeInfo') {
        return '연락처'
      }
    },
    search (searchParams) {
      // searchSearch
      this.searchParams = this._.cloneDeep(searchParams)
      this.searchAct('SEARCH_BUTTON')
    },
    //메세지 박스 생성
    makeAlert (doCheck, id, arrTooltip, type) {
      const msgHtml = []
      const css = 'tooltip_wrap position_absolute'
      const style = 'width:250px; display:none; left:-100px; top:30px;'
      msgHtml.push(`<div id="${doCheck}_tt_${id}" onmouseout="vmAppIm.hideTooltip(event, '${id}', '${doCheck}')" class="${css}" style="${style}">`)
      msgHtml.push('<div class="cont text_left">')
      msgHtml.push('<ul class="bul_list_sm t2">')
      if (type === 1) {
        msgHtml.push('<li class="text_left">')
      } else {
        msgHtml.push('<li class="text_left tt_cont">')
      }
      msgHtml.push(arrTooltip.join('<br/>'))
      msgHtml.push('</li>')
      msgHtml.push('</ul>')
      msgHtml.push('</div>')
      msgHtml.push('</div>')
      return msgHtml.join('')
    },
    // 검색 실행, list reload
    async searchAct (act) {
      let msnYn = 'N'

      if (this.searchParams.kind === '02') {
        const blNo = this.searchParams.keyword

        await importSvc.getMsnYn(blNo).then(res => {
          msnYn = res.data
        })

        if (msnYn === 'Y') {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.028') }) // 선적지로부터 B/L DATA가 접수 후 조회 가능합니다.
          return
        }
      }

      await importSvc.getOnGoingList(this.searchParams).then(res => {
        const list = res.data
        this.setGridData(list)
        this.tempData = {}
        // 검색 버튼 클릭시
        if (act === 'SEARCH_BUTTON' && (list === undefined || list.length === 0)) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        } else {
          if (this.searchParams.kind === '02') {
            gridViewIm.checkItem(0, true)
            const rows = gridViewIm.getCheckedRows(true)
            this.setRequestArea(rows)
          }
        }

        //setTimeout(() => {
        //  this.closeRequestArea()
        //}, 1000)
      })
    },
    // filter
    changeFilter () {
      if (this.list === undefined || this.list.length === 0) {
        return
      }

      let arrFilter

      if (this.txtFilter.indexOf(',') !== -1) {
        arrFilter = this.txtFilter.split(',')
      } else {
        arrFilter = [this.txtFilter]
      }

      if (this.txtFilter === '') {
        this.gridList = this.list
      } else {
        this.gridList = this.list.filter(vo => {
          const blNo = vo.blNo === undefined ? '' : vo.blNo.toLowerCase()
          const cneCstEnm = vo.cneCstEnm === undefined ? '' : vo.cneCstEnm.toLowerCase()
          const vslNm = vo.vslNm === undefined ? '' : vo.vslNm.toLowerCase()
          const cyEnm = vo.cyEnm === undefined ? '' : vo.cyEnm.toLowerCase()
          const cntrQty = vo.cntrQty === undefined ? '' : vo.cntrQty.toLowerCase()
          const podPortNm = vo.podPortNm === undefined ? '' : vo.podPortNm.toLowerCase()
          const polPortNm = vo.polPortNm === undefined ? '' : vo.polPortNm.toLowerCase()
          let txtFilter

          for (let i in arrFilter) {
            txtFilter = arrFilter[i].toLowerCase().replace(/^\s+|\s+$/g, '')
            if (txtFilter === '') {
              continue
            }
            if (blNo.indexOf(txtFilter) !== -1 || cneCstEnm.indexOf(txtFilter) !== -1 ||
            vslNm.indexOf(txtFilter) !== -1 || podPortNm.indexOf(txtFilter) !== -1 ||
            polPortNm.indexOf(txtFilter) !== -1 || cyEnm.indexOf(txtFilter) !== -1 || cntrQty.indexOf(txtFilter) !== -1) {
              return true
            }
          }
          return false
        })
      }
      this.totalCount = this.gridList.length
      providerIm.setRows(this.gridList)
    },
    gridEventsIm () {
      const TH = this

      gridViewIm.onItemAllChecked = (grid, checked) => {
        if (checked) {
          let rows = providerIm.getRows().map((v, i) => i)
          TH.setRequestArea(rows)
        } else {
          TH.setRequestArea([])
        }
      }

      gridViewIm.onCellClicked = (grid, clickData) => {
        if (clickData.cellType === 'header' || clickData.cellType === 'head') {
          return
        }

        if (clickData.cellType === 'check') {
          TH.setRequestArea(grid.getCheckedRows())
          // return
        }
      }

      // gridViewIm.onCopy = (grid, range, event) => {
      //   const data = grid.getSelectionData()
      //   const extractTextPattern = /(<([^>]+)>)/gi
      //   const arrCopy = []
      //   if (data.length > 0) {
      //     data.forEach((obj, idx) => {
      //       const values = Object.values(obj)
      //       values.forEach((sub, subidx) => {
      //         arrCopy.push(sub.replace(extractTextPattern, ''))
      //         if (subidx < (values.length - 1)) arrCopy.push(' ')
      //       })
      //       if (idx < (data.length - 1)) arrCopy.push('\n')
      //     })
      //   }
      //   if (arrCopy.length > 0) {
      //     if (window.clipboardData) {
      //       window.clipboardData.setData('Text', arrCopy.join(''))
      //     } else {
      //       event.clipboardData.setData('text/plain', arrCopy.join(''))
      //     }
      //   }
      //   return false
      // }
    },
    setGridData (list) {
      this.totalCount = list === undefined ? 0 : list.length
      this.list = list

      const tempPrevData = sessionStorage.getItem('working-progress-import-prev-data')
      const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)

      if (prevData !== undefined && prevData.txtFilter !== '') {
        this.txtFilter = prevData.txtFilter
      } else {
        this.txtFilter = ''
        //providerIm.setRows(list)
      }
      this.changeFilter()

      if (prevData !== undefined) {
        if (prevData.checkedRows.length > 0) {
          gridViewIm.checkItems(prevData.checkedRows, true)
          this.setRequestArea(gridViewIm.getCheckedRows())
        }

        if (prevData.gridSelect !== undefined) {
          gridViewIm.setCurrent(prevData.gridSelect)
        }
      }
      sessionStorage.removeItem('working-progress-import-prev-data')
    },
    changeColumn (e, chgType) {
      e.preventDefault()
      if (this.colType === chgType) {
        return
      }
      this.colType = chgType
      if (this.colType === 'doc') {
        gridViewIm.setColumns(columns1Im)
        gridViewIm.setColumnLayout(layout1Im)
      } else {
        gridViewIm.setColumns(columns2Im)
        gridViewIm.setColumnLayout(layout2Im)
      }
    },
    setRequestArea (chkRows) {
      if (chkRows.length === 0) {
        this.requestAreaBtn = {
          charge01: 'N', // 운임 정정 요청
          charge02: 'N', // Free Time 연장 요청
          do01: 'N', // D/O 발행신청
          do02: 'N', // Surrender 문의
          do03: 'N', // D/O (OFC) 전송
          trans01: 'N', // 반납지 조회
          trans02: 'N', // 긴급양하 신청
          report01: 'N', // DELAY NOTICE
          report02: 'N', // B/L COPY
          report03: 'N', // INVOICE
          report04: 'N', // A/N
          report05: 'N', // 적하목록
          report06: 'N', // D/O
          report07: 'N', // Freetime 승인 내역
          report08: 'N', // 인도네이사만 해당
          report09: 'N', // 관리대상 지정여부 (즉시검사(I), XRAY검사(X)인 경우만 해당함)
          report10: 'N' // CNTR 개별중량 조회
        }
        this.checkedCount = 0
        return
      }

      this.checkedCount = chkRows.length

      const searchList = []
      let isSameSchedule = true
      let prePolPortCd = ''
      let prePodPortCd = ''
      let preVslCd = ''
      let preVoyNo = ''

      chkRows.forEach(i => {
        // const blNo = gridViewIm.getValue(i, 'blNo') || ''
        // const polPortCd = gridViewIm.getValue(i, 'polPortCd') || ''
        // const podPortCd = gridViewIm.getValue(i, 'podPortCd') || ''
        // const vslCd = gridViewIm.getValue(i, 'vslCd') || ''
        // const voyNo = gridViewIm.getValue(i, 'voyNo') || ''
        const blNo = this.gridList[i].blNo || ''
        const polPortCd = this.gridList[i].polPortCd || ''
        const podPortCd = this.gridList[i].podPortCd || ''
        const vslCd = this.gridList[i].vslCd || ''
        const voyNo = this.gridList[i].voyNo || ''
        searchList.push({ blNo: blNo, userId: this.auth.userId })

        // pol, pod, 선명/항차 동일여부 확인
        if (isSameSchedule) {
          if (prePolPortCd !== '' && prePolPortCd !== polPortCd) {
            isSameSchedule = false
          }
          if (prePodPortCd !== '' && prePodPortCd !== podPortCd) {
            isSameSchedule = false
          }
          if (preVslCd !== '' && preVslCd !== vslCd) {
            isSameSchedule = false
          }
          if (preVoyNo !== '' && preVoyNo !== voyNo) {
            isSameSchedule = false
          }
          prePolPortCd = polPortCd
          prePodPortCd = podPortCd
          preVslCd = vslCd
          preVoyNo = voyNo
        }
      })

      if (isSameSchedule && (prePolPortCd === '' || prePodPortCd === '' || preVslCd === '' || preVoyNo === '')) {
        isSameSchedule = false
      }

      const checkCnt = searchList.length
      const params = {
        searchList: searchList
      }

      importSvc.getOnGoingImportRequestBtn(params).then(res => {
        this.requestAreaBtn = res.data
        // 다중 업무 수행 불가 항목
        if (checkCnt > 1) {
          // 정산
          this.requestAreaBtn.charge01 = 'N' // 운임 정정 요청
          this.requestAreaBtn.charge02 = 'N' // Freetime 연장 요청

          // D/O
          // this.requestAreaBtn.do01 = 'N' // D/O 발행신청
          this.requestAreaBtn.do02 = 'N' // Surrender 문의
          // this.requestAreaBtn.do03 = 'N' // D/O (OFC) 전송

          // 운송
          this.requestAreaBtn.trans01 = 'N' // 위험물 반입 요청
          this.requestAreaBtn.trans02 = 'N' // Full 컨테이너 반출

          this.requestAreaBtn.report10 = 'N'
        } else {
          this.requestAreaBtn.report10 = 'Y'
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getData (paramBlNo) {
      const vo = this.list.find(tvo => {
        const blNo = tvo.blNo
        return blNo === paramBlNo
      })
      return vo
    },
    seawaybillNoticePop (blNo, shipper) {
      // 1401941 본인 또는 수임자가 아닌 경우, WAYBILL LIST 연결 해제 요청
      if (shipper === '**') {
        if (this.memberDetail.userCtrCd === 'KR') {
          this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
        } else {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        }

        return
      }

      this.popupParams.SeawaybillNoticePop.items = []
      this.popupParams.SeawaybillNoticePop.items.push({ blNo: blNo })
      this.openPopup('SeawaybillNoticePop')
    },
    surrenderNoticePop (blNo, shipper) {
      // 본인과 수임자가 아닌 경우에는 SURRENDER LIST 팝업되지 않게 셋팅
      if (shipper === '**') {
        if (this.memberDetail.userCtrCd === 'KR') {
          this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
        } else {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        }

        return
      }

      this.popupParams.SurrenderNoticePop.items = []
      this.popupParams.SurrenderNoticePop.items.push({ blNo: blNo })
      this.openPopup('SurrenderNoticePop')
    },
    unipassByBlNo (blNo) {
      if (this.auth.userCtrCd !== 'KR') { return }
      // 사용자의 동의를 얻고 클립보드에 문자열을 복사
      navigator.clipboard.writeText('KMTC' + blNo)
        .then(() => {
          window.open('https://unipass.customs.go.kr/', '_blank')
        })
        .catch(err => {
          console.error('클립보드에 복사하는 도중 오류가 발생했습니다:', err)
        })
    },
    // 요청사항 - 정산
    async fnRequestCharge (type) {
      const key = 'charge' + type
      if (type !== '03' && this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridViewIm.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }

      //const blNo = gridViewIm.getValue(checkedRows[0], 'blNo')
      const blNo = this.gridList[checkedRows[0]].blNo

      if (type === '01') {
        // 운임 정정 요청
        // this.$ekmtcCommon.alertDefault('운임 정정 요청(' + blNo + ')')
        const params = {
          blNo: blNo,
          remarkCd: ''
        }
        this.isShowPop = true
        this.openTp2Popup('FreightPop', params)
      } else if (type === '02') {
        // Free Time 연장 요청
        // this.$ekmtcCommon.alertDefault('Free Time 연장 요청(' + blNo + ')')
        // this.popupParams.FreeTimeExtensReqPop.blNo = blNo
        // this.popupParams.FreeTimeExtensReqPop.eiCatCd = 'I'
        // this.popupParams.FreeTimeExtensReqPop.sr = '1'
        // this.openPopup('FreeTimeExtensReqPop')
        // setTimeout(() => {
        //   const elemPopup = document.querySelector('.popup_wrap')
        //   elemPopup.style.height = '600px'
        // }, 100)
        const query = {
          eiCatCd: 'I',
          dtKnd: 'BL',
          num: blNo
        }
        this.$router.push({ name: 'FreeTimeRequest', query: query }).catch(() => {})
      } else {
        // Payment
        // this.$ekmtcCommon.alertDefault('Payment(' + blNo + ')')
        await payment.findPayReqYn({ blNo: blNo })
          .then(async response => {
            const payReqYn = response.data.payReqYn
            const houseBlStsCd = response.data.houseBlStsCd

            if (payReqYn === 'Y') {
              this.$ekmtcCommon.alertDefault('Payment request is received and in process.')
            } else {
              if (houseBlStsCd === '00') {
                if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.501'), useConfirmBtn: true })) {
                  this.popupParams.PaymentRegPop.blNo = blNo
                  this.openPopup('PaymentRegPop')
                }
              } else if (houseBlStsCd === '03') {
                this.popupParams.PaymentRegPop.blNo = blNo
                this.openPopup('PaymentRegPop')
              } else {
                this.$ekmtcCommon.alertDefault('House B/L has not yet been approved.')
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    openTp2Popup (compNm, params) {
      this.popupTp2Params = params
      this.popupTp2Component = compNm
      this.$ekmtcCommon.layerOpen('.import_ongoing_list_popup_tp2')
      setTimeout(() => {
        const elemPopup = document.querySelector('.popup_wrap')
        elemPopup.style.height = '600px'
      }, 100)
    },
    closeTp2Popup () {
      this.popupTp2Params = {}
      this.popupTp2Component = null
      this.popupTp2Callback = ''
      this.$ekmtcCommon.layerClose('.import_ongoing_list_popup_tp2')
    },
    // 요청사항 - D/O
    async fnRequestDO (type) {
      const key = 'do' + type
      if (this.requestAreaBtn[key] !== 'Y') {
        return
      }
      const checkedRows = gridViewIm.getCheckedRows()
      if (checkedRows.length === 0) {
        return
      }

      if (type === '011') {
        const arrBlNo = []
        const TH = this
        checkedRows.forEach(vo => {
          const blNo = TH.gridList[vo].blNo || ''
          arrBlNo.push(blNo)
        })
        this.$refs.commonUnsolved.openDoPaymentPop(arrBlNo, 'I') // 수출: O, 수입: I
      } else if (type === '01') {
        const arrBlNo = []
        const TH = this
        checkedRows.forEach(vo => {
          const blNo = TH.gridList[vo].blNo || ''
          arrBlNo.push(blNo)
        })
        // 정산 발행 화면으로 이동
        const searchParams = {
          eiCatCd: 'I',
          tab: '1',
          dtKnd: 'BL',
          asProfileYn: 'N',
          keyword: arrBlNo.join(',')
        }
        this.$router.push({ name: 'calcIssueMain', params: searchParams }).catch(() => {})
      } else if (type === '02') {
        // this.$ekmtcCommon.alertDefault('Surrender 문의(' + blNo + ')')
        // this.popupParams.SurrenderReqEmailPop.blNo = gridViewIm.getValue(checkedRows[0], 'blNo')
        // this.popupParams.SurrenderReqEmailPop.voyNo = gridViewIm.getValue(checkedRows[0], 'voyNo')
        // this.popupParams.SurrenderReqEmailPop.vslNm = gridViewIm.getValue(checkedRows[0], 'vslNm')
        // this.popupParams.SurrenderReqEmailPop.podPortCd = gridViewIm.getValue(checkedRows[0], 'podPortCd')
        this.popupParams.SurrenderReqEmailPop.blNo = this.gridList[checkedRows[0]].blNo
        this.popupParams.SurrenderReqEmailPop.voyNo = this.gridList[checkedRows[0]].voyNo
        this.popupParams.SurrenderReqEmailPop.vslNm = this.gridList[checkedRows[0]].vslNm
        this.popupParams.SurrenderReqEmailPop.podPortCd = this.gridList[checkedRows[0]].podPortCd
        this.openPopup('SurrenderReqEmailPop')
      } else {
        const blNo = gridViewIm.getValue(checkedRows[0], 'blNo')
        // D/O (OFC) 전송
        // this.$ekmtcCommon.alertDefault('D/O (OFC) 전송(' + blNo + ')')
        let result = await doapi.sendKLNetDo({ blNo: blNo })
        let sendResult = result.data
        if (sendResult === '01') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM010T010.015')) // 반납지 정보가 KL-NET에 전송되었습니다. > 전송완료되었습니다.
        } else if (sendResult === '02') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.060')) // D/O가 발행되지 않았습니다.
        } else if (sendResult === '03') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.061')) // Demurrage가 초과되었습니다.
        } else if (sendResult === '04') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.062')) // Detention이 초과되었습니다.
        } else if (sendResult === '05') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.063')) // KL_NET DO SEND FAIL !!
        }
      }
    },
    // 요청사항 - 운송
    fnRequestTrans (type) {
      const key = 'trans' + type
      if (this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridViewIm.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }

      // const blNo = gridViewIm.getValue(checkedRows[0], 'blNo')
      // const podPortCd = gridViewIm.getValue(checkedRows[0], 'podPortCd')
      const blNo = this.gridList[checkedRows[0]].blNo
      const podPortCd = this.gridList[checkedRows[0]].podPortCd

      if (type === '01') {
        // TODO : 반납지 조회
        // this.$ekmtcCommon.alertDefault('반납지 조회(' + blNo + ')')
        const query = {
          searchType: 'BL',
          keyword: blNo
        }
        // this.$router.push({ name: 'container-return', query: query })
        // this.$router.push({ path: '/working/container-return', name: 'container-return', params: { blNo: blNo } }).catch(() => {})
        this.$router.push({ name: 'container-return', query: { searchType: 'BL', keyword: blNo } }).catch(() => {})
      } else {
        // 긴급양하 신청
        // this.$ekmtcCommon.alertDefault('긴급양하 신청(' + blNo + ' / ' + podPortCd + ')')
        this.popupParams.EmergencyShippingReqPop.blNo = blNo
        this.popupParams.EmergencyShippingReqPop.portCd = podPortCd
        this.openPopup('EmergencyShippingReqPop')
      }
    },
    async fnInvoicePrintOnGrid (blNo) {
      //국내 인보이스 권한
      if (this.auth.userCtrCd === 'KR') {
        const params = {
          blNo: blNo
        }
        let agentYn = ''
        await importSvc.getInvAgentChk(params).then(res => {
          agentYn = res.data
          // console.log('getInvAgentChk agentYn @@@@@@ ', agentYn)

          if (TH.memberDetail.staffFlag === 'Y') {
            agentYn = 'Y'
          }
        }).catch(err => {
          console.log(err)
        })
        if (agentYn === 'N') {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ADD20220219.002') })// '권한이 없는 B/L 입니다.'
          return
        }
      }

      this.auxInvoiceData = []

      this.popupParams.CombineInvoicePop.items = []
      this.popupParams.CombineInvoicePop.items.push({ blNo: blNo, remark: '' })

      this.openPopup('CombineInvoicePop')
    },
    // 요청사항 - 각종 출력
    async fnRequestReport (type) {
      const TH = this
      const key = 'report' + type
      if (type !== '08' && this.requestAreaBtn[key] !== 'Y') {
        return
      }

      const checkedRows = gridViewIm.getCheckedRows()

      if (checkedRows.length === 0) {
        return
      }

      //const blNo = gridViewIm.getValue(checkedRows[0], 'blNo')
      const blNo = this.gridList[checkedRows[0]].blNo

      this.popupParams.DelayNoticeInPop.items = []
      this.popupParams.BlCopyPop.items = []
      this.popupParams.CombineInvoicePop.items = []
      this.popupParams.BlInvoiceFreightPop.items = []
      this.popupParams.ArrivalNoticePop.items = []
      this.popupParams.CargoMainFestPop.items = []
      this.popupParams.DeliveryOrderPop.items = []
      this.popupParams.FreeTimePop.items = []
      this.popupParams.ReceiptPrintPop.items = []
      this.popupParams.InspecPrintPop.items = []

      if (type === '01') {
        // DELAY NOTICE
        // this.$ekmtcCommon.alertDefault('DELAY NOTICE(' + blNo + ')')
        let i = 1
        checkedRows.forEach(vo => {
          //const eachBlNo = this.gridList[checkedRows[0]].blNo
          const eachBlNo = this.gridList[vo].blNo
          const initObj = {
            docNo: i,
            prtGubun: '03BL',
            parm1: eachBlNo,
            userId: this.auth.userId,
            ctrCd: this.auth.userCtrCd
          }
          this.popupParams.DelayNoticeInPop.items.push(initObj)
          i++
        })
        this.openPopup('DelayNoticeInPop')
      } else if (type === '02') {
        // B/L COPY
        // this.$ekmtcCommon.alertDefault('B/L COPY(' + blNo + ')')
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          const eachBlNo = this.gridList[vo].blNo
          const initObj = {
            blNo: eachBlNo,
            porDlyYn: 'N',
            noCnee: 'N',
            noNotify: 'N'
          }
          this.popupParams.BlCopyPop.items.push(initObj)
        })
        this.openPopup('BlCopyPop')
      } else if (type === '03') {
        //국내 인보이스 권한
        if (this.auth.userCtrCd === 'KR') {
          const params = {
           blNo: blNo
          }
          let agentYn = ''
          await importSvc.getInvAgentChk(params).then(res => {
            agentYn = res.data
            // console.log('getInvAgentChk agentYn @@@@@@ ', agentYn)

            if (TH.memberDetail.staffFlag === 'Y') {
              agentYn = 'Y'
            }
          }).catch(err => {
            console.log(err)
          })
          if (agentYn === 'N') {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ADD20220219.002') })// '권한이 없는 B/L 입니다.'
              return
          }
        }
        this.auxInvoiceData = []
        // // INVOICE
        // this.$ekmtcCommon.alertDefault('INVOICE(' + blNo + ')')
        checkedRows.forEach(idx => {
          this.popupParams.CombineInvoicePop.items.push({ blNo: this.gridList[idx].blNo, remark: '' })

          if (this.gridList[idx].podCtrCd === 'ID' || this.gridList[idx].polCtrCd === 'ID') {
            this.auxInvoiceData.push({ blNo: blNo })
          }
        })

        this.openPopup('CombineInvoicePop')
        // const blList = []
        // checkedRows.forEach(vo => {
        //   //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
        //   const eachBlNo = this.gridList[vo].blNo
        //   blList.push({ blNo: eachBlNo })
        // })
        // const params = {
        //   blList: blList
        // }
        // importSvc.findInvoiceRno(params).then(res => {
        //   const resList = res.data
        //   for (const item of resList) {
        //     this.popupParams.CombineInvoicePop.items.push({ blNo: item.blNo, remark: '' })
        //   }
        //   // check test value
        //   // this.openPopup('BlInvoiceFreightPop')
        //   setTimeout(() => {
        //     this.openPopup('CombineInvoicePop')
        //   }, 100)
        // }).catch(err => {
        //   console.log(err)
        // })
      } else if (type === '04') {
        // A/N
        // this.$ekmtcCommon.alertDefault('A/N(' + blNo + ')')
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          const eachBlNo = this.gridList[vo].blNo
          const initObj = {
            blNo: eachBlNo
          }
          this.popupParams.ArrivalNoticePop.items.push(initObj)
        })
        this.openPopup('ArrivalNoticePop')
      } else if (type === '05') {
        // 적하목록
        // this.$ekmtcCommon.alertDefault('적하목록(' + blNo + ')')
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          //const eachVslCd = gridViewIm.getValue(vo, 'vslCd')
          //const eachVoyNo = gridViewIm.getValue(vo, 'voyNo')
          const eachBlNo = this.gridList[vo].blNo
          const eachVslCd = this.gridList[vo].vslCd
          const eachVoyNo = this.gridList[vo].voyNo
          const initObj = {
            blNo: eachBlNo,
            vslCd: eachVslCd,
            voyNo: eachVoyNo
          }
          this.popupParams.CargoMainFestPop.items.push(initObj)
        })
        this.openPopup('CargoMainFestPop')
      } else if (type === '06') {
        // D/O
        // this.$ekmtcCommon.alertDefault('D/O(' + blNo + ')')
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          const eachBlNo = this.gridList[vo].blNo
          const initObj = {
            blNo: eachBlNo,
            doRno: eachBlNo
          }
          this.popupParams.DeliveryOrderPop.items.push(initObj)
        })
        this.openPopup('DeliveryOrderPop')
      } else if (type === '07') {
        // Freetime 승인 내역
        // this.$ekmtcCommon.alertDefault('Freetime 승인 내역(' + blNo + ')')
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          const eachBlNo = this.gridList[vo].blNo
          const initObj = {
            blNo: eachBlNo
          }
          this.popupParams.FreeTimePop.items.push(initObj)
        })
        this.openPopup('FreeTimePop')
      } else if (type === '08') {
        // RECEIPT > ONIM099V080 popup
        // this.$ekmtcCommon.alertDefault('RECEIPT(' + blNo + ')')
        const blList = []
        checkedRows.forEach(vo => {
          //const eachBlNo = gridViewIm.getValue(vo, 'blNo')
          const eachBlNo = this.gridList[vo].blNo
          blList.push({ blNo: eachBlNo })
        })
        const params = {
          blList: blList
        }
        importSvc.findAIreportReceipt(params).then(res => {
          // TODO : check test value
          // this.popupParams.ReceiptPrintPop.testUsrNo = 'KONOIKE01'
          // this.popupParams.ReceiptPrintPop.testCtrCd = 'ID'
          const resList = res.data
          for (const item of resList) {
            const initObj = {
              rcvRno: item.rcvRno,
              prtRvstNo: item.prtRvstNo,
              cstNo: this.memberDetail.cstCd,
              // cstNo: 'KTII00',
              cstNm: item.cstNm
            }
            this.popupParams.ReceiptPrintPop.items.push(initObj)
            // this.popupParams.ReceiptPrintPop.testAgtCd = item.agtCd
          }
          // check test value
          this.openPopup('ReceiptPrintPop')
        }).catch(err => {
          console.log(err)
        })
      } else if (type === '09') {
        // 관리대상
        checkedRows.forEach(vo => {
          const eachBlNo = this.gridList[vo].blNo
          const eachVslCd = this.gridList[vo].vslCd
          const eachVoyNo = this.gridList[vo].voyNo
          const initObj = {
            blNo: eachBlNo,
            vslCd: eachVslCd,
            voyNo: eachVoyNo
          }
          this.popupParams.InspecPrintPop.items.push(initObj)
        })
        this.openPopup('InspecPrintPop')
      } else if (type === '10') {
        checkedRows.forEach(i => {
          const blNo = this.gridList[i].blNo
          this.popupParams.CntrSearchPop.blNo = blNo
        })

        this.openPopup('CntrSearchPop')
      }
    },
    showTooltip (e, id, type) {
      const ttId = type + '_tt_' + id
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipFrt (e, id, type) {
      $('.frtYn_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'BLTAX_' + id
      let tempList = this.tempData[key]
      if (tempList === undefined) {
        await importSvc.getTaxInfo({ blNo: id }).then(async rtnData => {
          tempList = rtnData.data
          this.tempData[key] = tempList
        })
      }
      let html = []
      if (tempList !== undefined) {
        // html.push('B/L No. : ' + tempList.blNo || '')
        html.push('세금계산서 번호 : ' + tempList.acshRno || '')
        html.push('금액 : ' + this.$ekmtcCommon.changeNumberFormat(tempList.slprcAmt || '0', { isComma: true }) + app.$t('msg.ONEX070G100.018'))
        html.push('상태 : ' + tempList.stsKind || '')
        html.push('발급 e-mail : ' + tempList.recptEmlAddr || '')
      }
      $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipCntnDis (e, id, type) {
      $('.cntrDis_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'CNTNDIS_' + id
      let tempList = this.tempData[key]
      if (tempList === undefined) {
        const params = {
          blNo: id,
          asInOut: 'I',
          asDemDetCatCd: '01'
        }
        await importSvc.findContainerInfo(params).then(async rtnData => {
          tempList = rtnData.data
          this.tempData[key] = tempList
        })
      }
      let html = []
      if (tempList !== undefined) {
        tempList.forEach(tvo => {
          // html.push('B/L No. : ' + tvo.blNo || '')
          html.push('- ' + tvo.cntrNo || '')
        })
      }
      $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    // 컨테이너 정보 Tooltip
    async showTooltipCntrInfo (e, id, type, blNo) {
      $('.cntrDis_tt').hide()
      $('.cntrGto_tt').hide()
      $('.cntrRtn_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'CNTR_' + id
      let tvo = this.tempData[key]

      if (tvo === undefined) {
        const params = {
          bkgNo: '',
          blNo: blNo,
          type: 'ALL'
        }

        await exportSvc.getOnGoingCntrInfo(params).then(async res => {
          tvo = res.data
          this.tempData[key] = tvo
        })
      }

      let text = ''
      if (type === 'cntrDis') {
        text = tvo.cntrInfoDis || ''
      } else if (type === 'cntrGto') {
        text = tvo.cntrInfoGto || ''
      } else if (type === 'cntrRtn') {
        text = tvo.cntrInfoRtn || ''
      }
      // if (text !== '') {
      $('#' + ttId).find('.tt_cont').html(text.split('\n').join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
      // }
    },
    async showTooltipCntnGto (e, id, type, trmlNm) {
      $('.cntrGto_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'CNTNGTO_' + id
      let tempList = this.tempData[key]
      if (tempList === undefined) {
        const params = {
          blNo: id,
          asInOut: 'I',
          asDemDetCatCd: '01'
        }
        await importSvc.findContainerInfo(params).then(async rtnData => {
          tempList = rtnData.data
          this.tempData[key] = tempList
        })
      }
      let html = []
      if (tempList !== undefined) {
        let totAmt = 0
        tempList.forEach(tvo => {
          totAmt += parseInt(tvo.totAmt)
        })
        html.push('Terminal : ' + trmlNm)
        if (totAmt > 0) {
          html.push(`DEM ${app.$t('msg.ONIM010T010.006')} : <a class="link" onclick="vmAppIm.goFreetimeRequest(event, '${id}')">${app.$t('msg.ONIM010T010.007')}</a>`) // 발생여부 발생
        } else {
          html.push(`DEM ${app.$t('msg.ONIM010T010.006')} : ${app.$t('msg.ONIM010T010.008')}`) // 발생여부 미발생
        }
      }
      $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipCntnRtn (e, id, type) {
      $('.cntrRtn_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'CNTNRTN_' + id
      let tempList = this.tempData[key]
      if (tempList === undefined) {
        const params = {
          blNo: id,
          asInOut: 'I',
          asDemDetCatCd: '02'
        }
        await importSvc.findContainerInfo(params).then(async rtnData => {
          tempList = rtnData.data
          this.tempData[key] = tempList
        })
      }
      let html = []
      if (tempList !== undefined) {
        let totAmt = 0
        let trmlNm
        tempList.forEach(tvo => {
          totAmt += parseInt(tvo.totAmt)
          trmlNm = tvo.cyEnm
        })
        html.push('Terminal : ' + trmlNm)
        if (totAmt > 0) {
          html.push(`DET ${app.$t('msg.ONIM010T010.006')} : <a class="link" onclick="vmAppIm.goFreetimeRequest(event, '${id}')">${app.$t('msg.ONIM010T010.007')}</a>`) // 발생여부 발생
        } else {
          html.push(`DET ${app.$t('msg.ONIM010T010.006')} : ${app.$t('msg.ONIM010T010.008')}`) // 발생여부 미발생
        }
      }
      $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
      $('#' + ttId).show()
      this.tooltipTopCheck(ttId)
    },
    async showTooltipVslNm (e, id, type) {
      $('.vslNm_tt').hide()
      const ttId = type + '_tt_' + id
      const key = 'VSLNM_' + id
      let tempList = this.tempData[key]
      if (tempList === undefined) {
        const params = {
          blNo: id
        }

        await importSvc.getVesselChangeReason(params).then(async res => {
          tempList = res.data
          this.tempData[key] = tempList
        })
      }
      let html = []
      let logCont = []

      if (this.$ekmtcCommon.isNotEmpty(tempList)) {
        logCont = tempList.substring(tempList.indexOf('[') + 1, tempList.indexOf(']'))
        html.push('VESSEL CHANGE REASON')
        html.push(`: ${logCont}`)

        $('#' + ttId).find('.tt_cont').html(html.join('<br/>'))
        $('#' + ttId).show()
        this.tooltipTopCheck(ttId)
      }
    },
    goFreetimeRequest (e, id) {
      // this.$ekmtcCommon.alertDefault(id + ' < Freetime 요청 화면 이동')
      const query = {
        dtKnd: 'BL',
        blNo: id,
        num: id
      }
      this.$router.push({ name: 'FreeTimeRequest', query: query }).catch(() => {})
    },
    tooltipTopCheck (ttId) {
      const objId = $('#' + ttId)
      const tbody = objId.parents('tbody').eq(0)
      const tr = objId.parents('tr').eq(0)
      const arrTr = tbody.find('> tr')
      const rnum = arrTr.index(tr) + 1
      const td = objId.closest('td').eq(0)
      const arrTd = tr.find('td')
      const tdIdx = arrTd.index(td)
      const width = parseInt($('#' + ttId.replace('_tt_', '_btn_')).css('width'))
      const calCnt = (arrTd.length > 12) ? 4 : 3
      const td_width = parseInt(arrTd.eq(tdIdx).css('width'))
      if (tdIdx <= (arrTd.length - calCnt)) {
        $('#' + ttId).css({ left: (width + ((td_width - width) / 2)) + 'px' })
      } else {
        const orgWidth = parseInt(objId.css('width'))
        $('#' + ttId).css({ left: ((orgWidth - ((td_width / 2) - 6)) * -1) + 'px' })
      }
      if (Math.round(arrTr.length / 2) < rnum) {
        const height = Math.round((parseInt($('#' + ttId).css('height')) / 2))
        $('#' + ttId).css({ top: ((height) * -1) + 'px' })
      }
    },
    closeTooltip (e, id, type) {
      e.preventDefault()
      const ttId = type + '_tt_' + id
      $('#' + ttId).hide()
    },
    hideTooltip (e, id, type) {
      let tid = ''
      if (this.$ekmtcCommon.isNotEmpty(e.relatedTarget)) {
        tid = e.relatedTarget.id
      }
      const btnId = type + '_btn_' + id
      const ttId = type + '_tt_' + id
      if (tid !== btnId && tid !== ttId && $(e.relatedTarget).parents('#' + btnId).length === 0 && $(e.relatedTarget).parents('#' + ttId).length === 0) {
        $('#' + ttId).hide()
      }
    },
    isHaveAuth (bizCd, blNo) {
      const authParams = {
        bizCd: bizCd,
        rqRno: blNo,
        eiCatCd: 'I'
      }
      transCommon.isHaveAuth(authParams).then(res => {
        return res.data
      })
    },
    async fnClickAnChk (e, blNo) {
      e.preventDefault()
      let auth = 'Y'
      const authParams = {
        bizCd: 'AN',
        rqRno: blNo,
        eiCatCd: 'I'
      }
      const params = { searchList: [{ blNo: blNo, userId: this.auth.userId }] }
      await importSvc.getOnGoingImportRequestBtn(params).then(async res => {
        if (res.data.report02 !== 'Y') {
          auth = 'N'
        }
      }).catch(err => {
        console.log(err)
      })

      if (auth === 'Y') {
        await transCommon.isHaveAuth(authParams).then(async res => {
          auth = res.data
        })
      }

      if (auth !== 'Y') {
        if (this.memberDetail.userCtrCd === 'KR') {
          this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
        } else {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        }

        return
      }
      // const auth = this.isHaveAuth('AN', blNo)
      // if (auth !== 'Y') {
      //   this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
      //   return
      // }
      // this.$ekmtcCommon.alertDefault(blNo + ' < A/N 출력 화면')
      this.popupParams.ArrivalNoticePop.items = []
      this.popupParams.ArrivalNoticePop.items.push({ blNo: blNo })
      this.openPopup('ArrivalNoticePop')
    },
    async fnClickMfYn (e, blNo, vslCd, voyNo, seeYn) {
      e.preventDefault()
      // this.$ekmtcCommon.alertDefault(blNo + ' < B/L의 적하목록 출력 화면')
      let auth = 'Y'
      const params = { searchList: [{ blNo: blNo, userId: this.auth.userId }] }
      await importSvc.getOnGoingImportRequestBtn(params).then(async res => {
        if (res.data.report02 !== 'Y') {
          auth = 'N'
        }
      }).catch(err => {
        console.log(err)
      })
      // if (seeYn === 'N') {
      if (auth === 'N') {
        if (this.memberDetail.userCtrCd === 'KR') {
          this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
        } else {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        }
        return
      }
      this.popupParams.CargoMainFestPop.items = []
      const initObj = {
        blNo: blNo,
        vslCd: vslCd,
        voyNo: voyNo
      }
      this.popupParams.CargoMainFestPop.items.push(initObj)
      this.openPopup('CargoMainFestPop')
    },
    async fnClickFrtYn (e, blNo, type, polCtrCd, podCtrCd) {
      e.preventDefault()
      if (type === 'FRT_REQUEST') {
        // 운임등록 요청
        // this.openPopup('FareRegisReqPop')
        this.fnFareRegisReqPop(polCtrCd, podCtrCd)
      } else {
        if (blNo) {
          //CD_ID = 01007 (02: 운임)
          let delegateYn = 'N'
          // await transCommon.getBlDelegateYn({ blNo, arrCstGrpCatCd: ['02', '10'] }).then(async (res) => {
          //   if (res.data === 'Y') {
          //     delegateYn = 'Y'
          //   }
          // })
          // 권한 : 각종출력 Invoice 동일
          const params = { searchList: [{ blNo: blNo, userId: this.auth.userId }] }
          await importSvc.getOnGoingImportRequestBtn(params).then(res => {
            delegateYn = res.data.report03
          })
          await trans.getBlPaymentAccountlessList({ blNo, delegateYn })
          .then((response) => {
            const list = response.data.paymentAccountlessList
            if (list.length > 0) {
              //셋팅할 데이터 to array
              list.map((item) => {
                  item.totalAmt = item.occrCurCd + ' ' + item.occrAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  item.locAmtDesc = item.occrCurCd + ' ' + item.occrAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              })
              this.popupParams.BLPaymentAccountlessPop.blNo = blNo
              this.popupParams.BLPaymentAccountlessPop.list = list
              this.openPopup('BLPaymentAccountlessPop')
            } else {
              if (this.memberDetail.userCtrCd === 'KR') {
                this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
              } else {
                this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
              }
            }
          })
        }
      }
    },
    async fnClickFrtAmtAuth (e, blNo, frtAmtAuth) {
      // 운임 미정산일 경우 따로 처리
      e.preventDefault()
      if (frtAmtAuth === 'NG') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM070G010.038') })
      } else {
        if (blNo) {
          //CD_ID = 01007 (02: 운임)
          let delegateYn = 'N'
          // await transCommon.getBlDelegateYn({ blNo, arrCstGrpCatCd: ['02', '10'] }).then(async (res) => {
          //   if (res.data === 'Y') {
          //     delegateYn = 'Y'
          //   }
          // })
          // 권한 : 각종출력 Invoice 동일
          const params = { searchList: [{ blNo: blNo, userId: this.auth.userId }] }
          await importSvc.getOnGoingImportRequestBtn(params).then(res => {
            delegateYn = res.data.report03
          })
          await trans.getBlPaymentAccountlessList({ blNo, delegateYn })
          .then((response) => {
            const list = response.data.paymentAccountlessList
            if (list.length > 0) {
              //셋팅할 데이터 to array
              list.map((item) => {
                  item.totalAmt = item.occrCurCd + ' ' + item.occrAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  item.locAmtDesc = item.occrCurCd + ' ' + item.occrAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              })
              this.popupParams.BLPaymentAccountlessPop.blNo = blNo
              this.popupParams.BLPaymentAccountlessPop.list = list
              this.openPopup('BLPaymentAccountlessPop')
            } else {
              if (this.memberDetail.userCtrCd === 'KR') {
                this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
              } else {
                this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
              }
            }
          })
        }
      }
    },
    fnFareRegisReqPop (polCtrCd, podCtrCd) {
      const tmpParam = {
        porCd: polCtrCd,
        dlyCd: podCtrCd
      }
      schedule.findSalUno(tmpParam).then((response) => {
        if (response.status === 200) {
          const respData = response.data
          if (respData.eiCatCd !== undefined) {
            this.params.eiCatCd = respData.eiCatCd
            const eiCatCd = this.params.eiCatCd
            if (eiCatCd === 'O' || eiCatCd === 'OJ' || eiCatCd === 'OJRF') {
              this.params.frtTerm = 'P'
            } else if (eiCatCd === 'I') {
              this.params.frtTerm = 'C'
            }
          }
          if (respData.salNm !== undefined) {
            this.params.salNm = respData.salNm
          }
          if (respData.salTelNo !== undefined) {
            this.params.salTelNo = respData.salTelNo
          }
          if (respData.salUno !== undefined) {
            this.params.salUno = respData.salUno
          }
          schedule.fnPopFreReq(this.params).then((response) => {
            const result = response.data.insertRateMngResult
            let msg = ''
            if (result !== undefined && result === 1) {
              //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
              msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
            } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
              msg = this.$t('msg.FARE010T010.024')
            } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
              //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
              msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
            } else {
              msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
            }
            this.$ekmtcCommon.alertDefault(msg)
          })
        }
      })
    },
    async fnClickDoCheck (e, blNo, type, txt) {
      // const doCheck = type.split('/')
      let doCheck = ''
      let doStr = ''
      if (type.indexOf('/') > -1) {
        doCheck = type.substring(0, 1)
        doStr = type.substring(2)
      } else {
        doCheck = type
      }
      if (doCheck === 'C') {
        let auth = ''
        const authParams = {
          bizCd: 'DO',
          rqRno: blNo,
          eiCatCd: 'I',
          dtKnd: 'BL'
        }
        await transCommon.isHaveAuth(authParams).then(async res => {
          auth = res.data
        })
        // D/O 권한체크
        await importSvc.getDOPdfChk({ blNo: blNo }).then(res => {
          auth = res.data
        })
        if (doStr === 'WEB') {
          if (auth !== 'Y') {
            if (this.memberDetail.userCtrCd === 'KR') {
              this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
            } else {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
            }
            return
          }
          // const auth = this.isHaveAuth('DO', blNo)
          // if (auth !== 'Y') {
          //   this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
          //   return
          // }
          // this.$ekmtcCommon.asyncAlertMessage({ message: 'D/O VIEWER 팝업' })
          this.popupParams.DeliveryOrderPop.items = []
          const initObj = {
            blNo: blNo,
            doRno: blNo
          }
          this.popupParams.DeliveryOrderPop.items.push(initObj)
          this.openPopup('DeliveryOrderPop')
        } else {
          let msg = doStr.replace(' EDI', '')
          if (msg === 'D/O 발행완료') {
            if (auth === 'Y') {
              msg = this.$t('msg.ONEX010T010.078') + this.$t('msg.ONEX010T010.079')
            } else {
              msg = this.$t('msg.ONEX010T010.078') + this.$t('msg.ONEX010T010.154')
            }
          }
          this.$ekmtcCommon.asyncAlertMessage({ message: msg })
        }
      } else if (doCheck === 'N') {
        if (doStr === 'B/L 미발행') {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM010T010.009') }) // B/L 이 발행되지 않았습니다.
        } else if (doStr === 'B/L 미접수') {
          if (txt === 'A7') {
            this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM010T010.018') }) // WAY BILL 이 미발행되었습니다.
          } else {
            this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM010T010.010') }) // O.B/L 또는 L/G 가 접수되지 않았습니다.
          }
        } else if (doStr === 'Request' || doStr === 'Pending') {
          if (txt === 'WEB') {
            this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONIM010T010.011') }) // D/O 발급이 진행 중입니다.
          } else {
            this.$ekmtcCommon.asyncAlertMessage({ message: doStr.replace(' EDI', '') + this.$t('msg.ONIM010T010.012') }) // 으로 D/O 발급이 진행 중입니다.
          }
        } else if (doStr.indexOf('Reject') > -1) {
          // 정산 발행 화면으로 이동
          const searchParams = {
            eiCatCd: 'I',
            tab: '1',
            dtKnd: 'BL',
            asProfileYn: 'N',
            keyword: blNo
          }
          this.$router.push({ name: 'calcIssueMain', params: searchParams }).catch(() => {})
        }
      } else if (doCheck === 'Y') {
        // 정산 발행 화면으로 이동
        const searchParams = {
          eiCatCd: 'I',
          tab: '1',
          dtKnd: 'BL',
          asProfileYn: 'N',
          keyword: blNo
        }
        this.$router.push({ name: 'calcIssueMain', params: searchParams }).catch(() => {})
      }
    },
    // pop-up
    async popVslSchedule (blNo, type) {
      const vo = this.getData(blNo)

      const polCtrCd = type !== '1' ? vo.polCtrCd : vo.depPolCtrCd
      const polPortCd = type !== '1' ? vo.tsPolPortCd : vo.polPortCd
      const podCtrCd = type !== '1' ? vo.podCtrCd : vo.depPodCtrCd
      const podPortCd = type !== '1' ? vo.podPortCd : vo.depPodPortCd
      const vslCd = type !== '1' ? vo.vslCd : vo.depVslCd
      const voyNo = type !== '1' ? vo.voyNo : vo.depVoyNo
      const polTrmlCd = type !== '1' ? vo.polTrmlCd : vo.depPolTrmlCd

      const params = {
        mobileYN: 'Y',
        startDate: vo.etd.substring(0, 8),
        endDate: vo.etd.substring(0, 8),
        startCtrCd: polCtrCd,
        startPlcCd: polPortCd,
        destCtrCd: podCtrCd,
        destPlcCd: podPortCd,
        searchYear: vo.etd.substring(0, 4),
        searchMonth: vo.etd.substring(4, 6),
        bound: 'O',
        calendarOrList: 'C'
      }

      const popParam = {
        bound: 'I',
        menuCd: '01',
        podPortCd,
        polPortCd,
        polTrmlCd: vo.polTrmlCd,
        vslCd,
        voyNo,
        blNo: blNo
      }

      await schedule.getLegSchedule(params).then((rtnData) => {
        const listSchedule = rtnData.data.listSchedule
        for (const item of listSchedule) {
          if (item.vslCd === vslCd && item.voyNo === voyNo) {
            popParam.bkgDocCls = this.$ekmtcCommon.changeDatePattern(item.bkgDocCls, '.')
            popParam.bkgMfCls = this.$ekmtcCommon.changeDatePattern(item.bkgMfCls, '.')
            popParam.bkgCgoCls = this.$ekmtcCommon.changeDatePattern(item.bkgCgoCls, '.')
            popParam.cfsCls = this.$ekmtcCommon.changeDatePattern(item.cfsCls, '.')
          }
        }
      })

      this.popupParams.VesselScheduleInfoPop = popParam
      this.openPopup('VesselScheduleInfoPop')
      // this.popupParams.VesselScheduleInfoPop.vslNm = vslNm
      // this.popupParams.VesselScheduleInfoPop.vslCd = vslCd
      // this.popupParams.VesselScheduleInfoPop.voyNo = voyNo
      // this.popupParams.VesselScheduleInfoPop.polPortCd = polPortCd
      // this.popupParams.VesselScheduleInfoPop.podPortCd = podPortCd
      // this.popupParams.VesselScheduleInfoPop.bound = 'I'
      // this.popupParams.VesselScheduleInfoPop.polTrmlCd = trmlCd
      // this.popupParams.VesselScheduleInfoPop.polCtrCd = polCtrCd
      // this.popupParams.VesselScheduleInfoPop.rteCd = rteCd
      // this.popupParams.VesselScheduleInfoPop.menuCd = '01'
    },
    popPorDlySchdule (blNo, polPortNm, podPortNm, polPortCd, podPortCd, vslCd, voyNo) {
      this.popupParams.PorDlySchedulePop.blNo = blNo
      this.popupParams.PorDlySchedulePop.polPortNm = polPortNm
      this.popupParams.PorDlySchedulePop.podPortNm = podPortNm
      this.popupParams.PorDlySchedulePop.polPortCd = polPortCd
      this.popupParams.PorDlySchedulePop.podPortCd = podPortCd
      this.popupParams.PorDlySchedulePop.vslCd = vslCd
      this.popupParams.PorDlySchedulePop.voyNo = voyNo
      this.openPopup('PorDlySchedulePop')
    },
    fnInsPecPop (asBlNo) {
      importSvc.findInspecCont({ asBlNo: asBlNo }).then(res => {
        // this.$ekmtcCommon.asyncAlertMessage({ message: res.data.cntrNoList, useConfirmBtn: true })
        let msg = this.$t('msg.ONIM010T010.013') + '<br>' + res.data.cntrNoList // 관리대상 지정 컨테이너
        this.$ekmtcCommon.alertDefault(msg)
      })
    },
    fnCgoTypPop (asBlNo, flag) {
      importSvc.popCgoEntry({ asBlNo: asBlNo, flag: flag }).then(res => {
        this.popupParams.ImportBLCntrCgoPop.data = res.data
        this.popupParams.ImportBLCntrCgoPop.flag = flag
        this.openPopup('ImportBLCntrCgoPop')
      })
    },
    importManagerPopCall (blNo, podPortCd, podCtrCd, vslCd, podTrmlCd) {
      this.popupParams.ImportManagerPop.blNo = blNo
      this.popupParams.ImportManagerPop.podPortCd = podPortCd
      this.popupParams.ImportManagerPop.podCtrCd = podCtrCd
      this.popupParams.ImportManagerPop.podTrmlCd = podTrmlCd
      this.popupParams.ImportManagerPop.vslCd = vslCd

      this.openPopup('ImportManagerPop')
    },
    openRequestArea () {
      $('#working-progress-import-request-area').stop().slideDown(300)
    },
    closeRequestArea () {
      $('#working-progress-import-request-area').stop().slideUp(300)
    },
    async openPopup (compNm) {
      if (compNm === 'CombineInvoicePop') {
        await this.recalculateFreightBillInfo()

        await this.auxInvoiceCreate()
      }

      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.infoData = this.popupParams[compNm]
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup (obj) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    async recalculateFreightBillInfo () {
      let blArrStr = ''

      for (let i = 0; i < this.popupParams.CombineInvoicePop.items.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += this.popupParams.CombineInvoicePop.items[i].blNo
      }

      let req = {
        blNo: blArrStr
      }

      await CalcIssue.recalculateFreightBillInfo(req).then(response => {
        console.log('Success')
      }).catch(e => {
        console.log(e)
      })
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridViewIm.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('menu.MENU03.010') + '_' + this.$t('msg.ONEX010T010.003'), // ,
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
//        applyDynamicStyles: true,  // 주석 풀면 서류, 컨테이너 토글시 엑셀 다운로드 오류 발생함. 풀지 말것.
        exportTemplate: true,
        allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU03.010') + '_' + this.$t('msg.ONEX010T010.003')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    }, /*
    exlDownload () { // 그리드 엑셀 다운로드
      let title = `${this.$t('menu.MENU03.010')}_${this.$t('msg.ONEX010T010.003')}` // 진행중인업무_수입
      this.$ekmtcCommon.gridExportExcel(gridViewIm, title)
    }, */
    async auxInvoiceCreate () {
      let blArrStr = ''

      if (this.auxInvoiceData.length > 0) {
        for (let i = 0; i < this.auxInvoiceData.length; i++) {
          if (blArrStr !== '') {
            blArrStr += ','
          }

          blArrStr += this.auxInvoiceData[i].blNo
        }

        let req = {
          blNo: blArrStr,
          eiCatCd: 'I'
        }

        await CalcIssue.auxInvoiceCreate(req).then(response => {
          console.log('Success')
        }).catch(e => {
          console.log(e)
        })
      }
    },
    checkSelectCtr () {
      if (this.selCtr !== '') {
        if (this.selPo === 'POL') {
          console.log('POL' + this.selCtr)
          this.gridList = this.list.filter(vo => vo.polCtrCd === this.selCtr)
        } else if (this.selPo === 'POD') {
          console.log('POD' + this.selCtr)
          this.gridList = this.list.filter(vo => vo.podCtrCd === this.selCtr)
        }
        this.totalCount = this.gridList.length
        providerIm.setRows(this.gridList)
      } else {
        this.gridList = this.list
        this.totalCount = this.gridList.length
        providerIm.setRows(this.gridList)
      }
    },
    openRfDataInfoPop (blNo) {
      console.log('openRfDataInfoPop blNo @@@@@ ', blNo)

      const params = {
        kind: 'working',
        cntrList: [],
        detailParams: {},
        selectCntrList: [],
        detailList: [],
        rank: '',
        blNo: blNo
      }

      this.callRfDataNotiPop(params)
    },
    callRfDataNotiPop (params) {
      const THIS = this

      this.popupTp3Callback = (p) => {
        THIS.closeTp3Popup()
        THIS.callRfDataInfoPop(p)
      }

      this.openTp3Popup('RfDataNotiPop', params)
    },
    callRfDataInfoPop (params) {
      this.openTp3Popup('RfDataInfoPop', params)
    },
    openTp3Popup (compNm, params) {
      this.popupTp3Params = params
      this.popupTp3Component = compNm
      this.$ekmtcCommon.layerOpen('.import_ongoing_list_popup_tp3')
    },
    closeTp3Popup () {
      this.popupTp3Params = {}
      this.popupTp3Component = null
      this.popupTp3Callback = ''
      this.$ekmtcCommon.layerClose('.import_ongoing_list_popup_tp3')
    },
    checkDoAuth (shipper) {
      if (shipper === '**') {
        if (this.memberDetail.userCtrCd === 'KR') {
          this.$ekmtcCommon.asyncAlertMessage({ message: '권한이 없습니다. 위임장 접수 후 열람 가능합니다. <div style="text-align: left;">\n[연락처]\nTEL : 051-797-7400 (내선 6번)\nFAX : 051-980-0504</div>' })
        } else {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        }
      }
    }
  }
}

</script>
